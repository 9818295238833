import React, { useState, useEffect, useRef } from 'react';

import Draft from '../../../../../Question/Draft/Draft';
import ResultsAnalysisPopup from '../ResultsAnalysisPopup/ResultsAnalysisPopup';

import { questionResultsAnalysisPopupActions } from '../../../helpers/constants';

import addIcon from '../../../../../../../../../assets/img/add.svg';

import styles from './QuestionSummary.module.css';

const QuestionSummary = props => {
  const { surveyId, questionId, resultsAnalysisData } = props;

  const [showResultsAnalysisPopup, setShowResultsAnalysisPopup] = useState(
    false
  );
  const [questionSummary, setQuestionSummary] = useState(
    resultsAnalysisData &&
      resultsAnalysisData.questionSummaries &&
      resultsAnalysisData.questionSummaries.length &&
      resultsAnalysisData.questionSummaries.some(
        qS => qS.questionId === questionId
      )
      ? resultsAnalysisData.questionSummaries.find(
          qS => qS.questionId === questionId
        )
      : null
  );

  const [showOptionsPopup, setShowOptionsPopup] = useState(false);

  const wrapperRef = useRef(null);

  const handleClickOutside = event => {
    if (
      !(
        wrapperRef &&
        wrapperRef.current &&
        wrapperRef.current.contains(event.target)
      )
    ) {
      setShowOptionsPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container}>
      {questionSummary && questionSummary.description ? (
        <div>
          <div className={styles.title}>
            Summary
            <div className={styles.optionsContainer} ref={wrapperRef}>
              <span
                className={styles.optionsButton}
                role="presentation"
                onClick={() => setShowOptionsPopup(prev => !prev)}
              >
                ...
              </span>
              {showOptionsPopup ? (
                <div className={styles.optionsPopup}>
                  <span
                    className={styles.optionAction}
                    role="presentation"
                    onClick={() =>
                      setShowResultsAnalysisPopup({
                        action:
                          questionResultsAnalysisPopupActions.UPDATE_SUMMARY,
                        id: questionSummary._id,
                        description: questionSummary.description
                      })
                    }
                  >
                    Edit summary
                  </span>
                  <span
                    className={styles.optionAction}
                    role="presentation"
                    onClick={() =>
                      setShowResultsAnalysisPopup({
                        action:
                          questionResultsAnalysisPopupActions.REMOVE_SUMMARY,
                        id: questionSummary._id
                      })
                    }
                  >
                    Remove summary
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <Draft
            block={{
              question: questionSummary.description,
              type: 'Question summary'
            }}
          />
        </div>
      ) : (
        <div
          className={styles.writeSummaryButton}
          role="presentation"
          onClick={() =>
            setShowResultsAnalysisPopup({
              action: questionResultsAnalysisPopupActions.CREATE_SUMMARY,
              description: ''
            })
          }
        >
          <img
            src={addIcon}
            className={styles.writeSummaryButtonAddIcon}
            alt="+"
          />
          <span className={styles.writeSummaryButtonText}>Write summary</span>
        </div>
      )}
      {showResultsAnalysisPopup ? (
        <ResultsAnalysisPopup
          showResultsAnalysisPopup={showResultsAnalysisPopup}
          setShowResultsAnalysisPopup={setShowResultsAnalysisPopup}
          surveyId={surveyId}
          questionId={questionId}
          setQuestionSummary={setQuestionSummary}
        />
      ) : null}
    </div>
  );
};

export default QuestionSummary;
