import React, { useRef } from 'react';

import styles from './YesNoRadio.module.css';

export default ({ key, label, description, value, onChange, disabled }) => {
  const textContainerRef = useRef(null);

  return (
    <div className={styles.row}>
      <div className={styles.textContainer} ref={textContainerRef}>
        <div className={styles.label}>{label} </div>
        {description && description.length ? (
          <div className={styles.description}>{description}</div>
        ) : null}
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.licenceSelectorRow}>
          <div className={styles.licenceCheckboxContainer}>
            <input
              type="radio"
              name={key}
              onChange={() => onChange(true)}
              checked={value === true}
              disabled={disabled}
            />
            Yes
          </div>
          <div className={styles.licenceCheckboxContainer}>
            <input
              type="radio"
              name={key}
              onChange={() => onChange(false)}
              checked={value === false}
              disabled={disabled}
            />
            No
          </div>
        </div>
      </div>
    </div>
  );
};
