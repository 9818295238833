import React, { useState } from 'react';

import tickIconWhite from '../../../../../../assets/img/tick_white.svg';
import dropDownBoldGreyIcon from '../../../../../../assets/img/dropdown-arrow-bold-grey.svg';

import AddInsight from '../AddInsight/AddInsight';

import styles from './Toolbar.module.css';

const RatingScaleToolbar = props => {
  const {
    filteringOnSelection,
    setFilteringOnSelection,
    setShowAddInsightsPopup,
    questionId,
    showCompareToTotalSample,
    allowedToCreateInsights
  } = props;
  const [toolbarActive, setToolbarActive] = useState(false);

  return (
    <>
      <div
        className={`${styles.container} ${
          toolbarActive ? styles.containerActive : ''
        }`}
      >
        {showCompareToTotalSample ? (
          <div
            className={`${styles.toolbarItem} ${styles.verticalAlign} ${
              styles.clickable
            }`}
            role="presentation"
            onClick={() => {
              setFilteringOnSelection(!filteringOnSelection);
            }}
          >
            <div
              className={`${styles.visibleBox} ${
                !filteringOnSelection ? styles.visibleBoxActive : null
              }`}
            >
              <img
                src={tickIconWhite}
                className={styles.visibleTick}
                alt="Tick"
              />
            </div>
            Compare to total sample
          </div>
        ) : null}
        {allowedToCreateInsights ? (
          <AddInsight
            setShowAddInsightsPopup={setShowAddInsightsPopup}
            questionId={questionId}
          />
        ) : null}
      </div>
      <img
        src={dropDownBoldGreyIcon}
        alt="Display toolbar"
        className={`${styles.dropDownToolbarIcon} ${
          !toolbarActive ? styles.dropDownToolbarIconInactive : ''
        }`}
        role="presentation"
        onClick={() => {
          setToolbarActive(!toolbarActive);
        }}
      />
    </>
  );
};

export default RatingScaleToolbar;
