import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';

import * as mixpanel from '../../../../mixpanel';

import Demographics from './Blocks/Demographics';
import HeaderStats from './Blocks/HeaderStats';
import ResponseTimes from './Blocks/ResponseTimes';
import Question from './Blocks/Question/Question';
import Export from './Blocks/Export';
import AppBar from '../../../base/components/AppBar/AppBar';
import FiltersBar from './Blocks/FiltersBar/FiltersBar';
import Popup from '../Popup';
import FlowChart from '../../../surveys/components/SurveyBuilderPage/SurveyBuilder/components/FlowNavigation/FlowChart';
import VideoPopup from '../VideoPopup/VideoPopup';
import Graph from './Blocks/Graphs/Graph';

import Workspaces from './Blocks/Workspaces/Workspaces';

import './CampaignResultsPage.css';
import useUserback from '../../../hooks/useUserback';

import generateSurveyLists from '../../../surveys/components/SurveyBuilderPage/SurveyBuilder/helpers/generateSurveyLists/generateSurveyLists';

import {
  GET_SURVEY_RESULTS_PAGE,
  GET_HIDDEN_WORD_CLOUD_WORDS
} from '../../../graphql/Survey';
import InsightsHeader from './Insights/components/InsightsHeader/InsightsHeader';
import { insightQuestionTypes } from './Insights/helpers/constants';
import Scoping from './Scoping/Scoping';
import { checkIfAllCookiesAllowed } from '../../../helpers/privacy';
import { CHART_VIEW_NAMES } from '../../helpers/constants';
import getSortedTemplatesAndQuestions from './Blocks/Graphs/helpers/getSortedTemplatesAndQuestions';
import EditGraphsPopup from './Blocks/Graphs/components/EditGraphsPopup/EditGraphsPopup';
import getPermissionValue from '../../../surveys/components/SurveyBuilderPage/SurveyBuilder/helpers/getPermissionValue';
import getInsightsPermissions from './Insights/helpers/getInsightsPermissions';
import InsightSettingsPopup from './Blocks/InsightSettingsPopup/InsightSettingsPopup';

const CampaignResults = props => {
  const {
    viewToken,
    isFetching,
    isResultsPageLoading,
    error,
    isAdmin,
    match,
    history,
    headerStats,
    onTimePrettyPrint,
    results,
    onToggleFilter,
    filtersRelation,
    nestedFilters,
    activeFilters,
    onSetActiveFilters,
    onResetFilters,
    onSetFiltersRelation,
    collectData,
    onGenerateCSV,
    resultBlocks,
    onLoadResultsPage,
    responseTimes,
    isAuthenticated,
    onAssignCategoresToOpenAnswer,
    unfilteredResults,
    isPremiumClient,
    clientEmail,
    unFilteredSideResults,
    onToggleSorting,
    activeSorting,
    setActiveSorting,
    activeFilteringOnSelection,
    setActiveFilteringOnSelection,
    activePresentationMode,
    setActivePresentationMode,
    activeChartView,
    setActiveChartView,
    onToggleActiveBooleanState,
    removeNestedFilter,
    getViewAllGraphStats
    // onEditCampaign,
    // onShowResults,
  } = props;

  useUserback({
    client: clientEmail,
    survey: match.params.id
  });

  const [detailsSection, setDetailsSection] = useState('demographics');
  const [mainPage, setMainPage] = useState('statistics'); // workspaces
  const [mainSection, setMainSection] = useState('summary');
  const [videoPopup, setVideoPopup] = useState(false);
  const [editGraphsPopup, setEditGraphsPopup] = useState(false);
  const [exportPopup, setExportPopup] = useState(false);
  const [nextStepPopup, setNextStepPopup] = useState(false);
  const [scrollingQuestions, setScrollingQuestions] = useState(false);
  const [showEditWorkspacePopup, setShowEditWorkspacePopup] = useState(false);
  const [showCreateWorkspacePopup, setShowCreateWorkspacePopup] = useState(
    false
  );
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showAddInsightsPopup, setShowAddInsightsPopup] = useState(false);
  const [activeInsightId, setActiveInsightId] = useState(null);
  const [showInsightsSettingsPopup, setShowInsightsSettingsPopup] = useState(
    false
  );

  const blocksResultsHeaderRef = useRef(null);

  const {
    data: { survey: { survey } = {} } = {},
    loading: loadingGetSurvey,
    refetch: surveyRefetch
  } = useQuery(GET_SURVEY_RESULTS_PAGE, {
    variables: {
      id: match.params.id,
      ...(viewToken ? { viewToken } : {})
    },
    fetchPolicy: 'network-only' // Don't change this, as it is noticed that in some cases content is not refreshed
  });

  const { data: { getHiddenWordCloudWords } = {} } = useQuery(
    GET_HIDDEN_WORD_CLOUD_WORDS,
    {
      variables: {
        ...(viewToken ? { viewToken } : {})
      }
    }
  );

  useEffect(
    () => {
      window.scrollTo(0, 0);
      if (survey) {
        onLoadResultsPage(match.params.id, survey);
      }
    },
    [survey]
  );

  const returnHiddenWords = () => {
    if (getHiddenWordCloudWords && getHiddenWordCloudWords.length)
      return getHiddenWordCloudWords.map(hiddenWord => hiddenWord.word);
    return [];
  };

  const questionsMapping =
    survey &&
    survey.questions &&
    Array.isArray(survey.questions) &&
    survey.questions.reduce(
      (acc, q) => ({
        ...acc,
        [q.id]: q
      }),
      {}
    );

  const orderQuestionsWithGroupNodes = survey
    ? generateSurveyLists(survey.content, questionsMapping)
    : [];

  const sortedQuestions =
    orderQuestionsWithGroupNodes &&
    resultBlocks &&
    Object.values(resultBlocks) &&
    Object.values(resultBlocks).length
      ? orderQuestionsWithGroupNodes.map((qsl, orderedQuestionIndex) => ({
          ...resultBlocks[qsl.id],
          globalIndex: orderedQuestionIndex + 1,
          formattedGlobalIndex:
            orderQuestionsWithGroupNodes[orderedQuestionIndex] &&
            orderQuestionsWithGroupNodes[orderedQuestionIndex]
              .formattedGlobalIndex
        }))
      : null;

  let filteredQuestions = sortedQuestions;

  if (sortedQuestions) {
    filteredQuestions = sortedQuestions.filter(
      q => survey.hiddenGraphs.flows.indexOf(q.id) === -1
    );
  }

  // Insights permissions
  const amPermissions = {
    allowedToCreateInsights: getPermissionValue(
      survey,
      'allowedToCreateInsights'
    ),
    allowedToViewInsights: getPermissionValue(survey, 'allowedToViewInsights')
  };
  const surveyInsightsPermissions =
    survey && survey.insightsData && survey.insightsData.permissions;
  const insightsPermissions = getInsightsPermissions(
    isAdmin,
    surveyInsightsPermissions,
    amPermissions
  );

  const onFlowChartPopupClose = () => {
    setMainSection('summary');
  };

  const getQuestionWordClouds = questionId => {
    if (survey && survey.wordClouds && survey.wordClouds.length) {
      const questionWordClouds = survey.wordClouds.filter(
        wordCloud => wordCloud.question === questionId
      );
      if (questionWordClouds.length) {
        return questionWordClouds;
      }
    }
    return null;
  };

  const options = {
    root: null,
    rootMargin: '-1px 0px 0px 0px',
    threshold: [1]
  };

  const callbackFunction = entries => {
    if (blocksResultsHeaderRef.current) {
      const [entry] = entries;
      if (scrollingQuestions !== !entry.isIntersecting) {
        setScrollingQuestions(!entry.isIntersecting);
      }
    }
  };

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver(callbackFunction, options);
        if (blocksResultsHeaderRef.current)
          observer.observe(blocksResultsHeaderRef.current);
        return () => {
          if (blocksResultsHeaderRef.current)
            observer.unobserve(blocksResultsHeaderRef.current);
        };
      }
    },
    [blocksResultsHeaderRef, options]
  );

  const copyLinkToClipboard = value => {
    if (value) {
      const dummy = document.createElement('input');
      document.body.appendChild(dummy);

      dummy.setAttribute('id', 'goalurl');
      document.getElementById('goalurl').value = value;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    }
  };

  const sortedTemplatesAndQuestions = survey
    ? getSortedTemplatesAndQuestions(filteredQuestions, activeInsightId, survey)
    : [];

  const getSortedTemplatesAndQuestionsElements = templatesAndQuestions => {
    if (!templatesAndQuestions || !templatesAndQuestions.length) {
      return [];
    }

    const page = [];

    templatesAndQuestions.forEach((templateQuestion, index) => {
      if (templateQuestion && templateQuestion.graphType) {
        page.push(
          <Graph
            key={`survey-graph-results-page-${index.toString()}`}
            surveyId={survey.id}
            surveyLanguage={survey.language}
            questions={filteredQuestions || []}
            activeFilters={activeFilters}
            onToggleFilter={onToggleFilter}
            setEditGraphsPopup={setEditGraphsPopup}
            surveyRefetch={surveyRefetch}
            graph={templateQuestion}
            index={index}
            surveyHiddenGraphs={survey.surveyHiddenGraphs}
            surveyMergedGraphs={survey.surveyMergedGraphs}
            isAllowedToExecuteRequests={!!(isAuthenticated || viewToken)}
            content={survey.content}
            orderQuestionsWithGroupNodes={orderQuestionsWithGroupNodes}
            viewToken={viewToken || null}
            activeInsightId={activeInsightId}
            insightsData={survey && survey.insightsData}
            setShowAddInsightsPopup={setShowAddInsightsPopup}
            onToggleActiveBooleanState={onToggleActiveBooleanState}
            activeFilteringOnSelection={activeFilteringOnSelection}
            activePresentationMode={activePresentationMode}
            activeChartView={activeChartView}
            setActiveChartView={setActiveChartView}
            getViewAllGraphStats={getViewAllGraphStats}
            setActiveInsightId={setActiveInsightId}
            allowedToCreateInsights={
              insightsPermissions && insightsPermissions.allowedToCreateInsights
            }
          />
        );
      }

      if (templateQuestion && templateQuestion.unfilteredResults) {
        page.push(
          <Question
            key={`survey-question-results-page-${index.toString()}`}
            index={index}
            block={templateQuestion}
            onToggleFilter={onToggleFilter}
            onTimePrettyPrint={onTimePrettyPrint}
            headerStats={headerStats}
            setVideoPopup={setVideoPopup}
            activeFilters={activeFilters}
            resultBlocks={resultBlocks}
            campaign={survey}
            onAssignCategoresToOpenAnswer={onAssignCategoresToOpenAnswer}
            isAdmin={isAdmin}
            isAllowedToExecuteRequests={!!(isAuthenticated || viewToken)}
            onToggleSorting={onToggleSorting}
            activeSorting={activeSorting}
            initialWordClouds={
              templateQuestion.type === 'Open Question'
                ? getQuestionWordClouds(templateQuestion.id)
                : []
            }
            hiddenWordCloudWords={
              templateQuestion.type === 'Open Question'
                ? returnHiddenWords()
                : []
            }
            viewToken={viewToken || null}
            setShowAddInsightsPopup={setShowAddInsightsPopup}
            activeInsightId={activeInsightId}
            onToggleActiveBooleanState={onToggleActiveBooleanState}
            activeFilteringOnSelection={
              activeFilteringOnSelection[templateQuestion.id]
            }
            chartView={
              activeChartView && activeChartView[templateQuestion.id]
                ? activeChartView[templateQuestion.id].chartViewName
                : CHART_VIEW_NAMES.DEFAULT
            }
            setChartView={chartViewState =>
              setActiveChartView({
                ...activeChartView,
                [templateQuestion.id]: { chartViewName: chartViewState }
              })
            }
            insightQuestionType={insightQuestionTypes.QUESTION}
            allowedToCreateInsights={
              insightsPermissions && insightsPermissions.allowedToCreateInsights
            }
          />
        );
      }
    });

    return page;
  };

  const page = [];

  const appBarProperties = {
    routeSubtitle:
      survey && !isFetching
        ? survey.projectName || survey.name || 'This is the Survey Name'
        : 'This is the Survey Name',
    /* path: 'builder', */
    absolutePosition: mainPage === 'statistics'
  };
  page.push(
    <AppBar
      {...props}
      appBarProperties={appBarProperties}
      isFetching={isFetching}
      key="app-bar"
    />
  );

  const loading = isFetching || loadingGetSurvey || isResultsPageLoading;

  // Loading state
  if (loading) {
    page.push(
      <div className="main-results-container" key="results-loading-state">
        <div className="content-results-header-loading">
          <div className="loader-container header-container" />
        </div>
        <div className="results-content-loading">
          <div className="action-results-column-container loader-container filters-container" />
          <div className="blocks-results-column-container blocks-results-loading">
            <div className="statistics-loading loader-container" />
            <div className="question-container loader-container" />
          </div>
        </div>
      </div>
    );
  }

  if (!loading && error) {
    page.push(
      <div className="main-results-container" key="results-loading-state">
        <div className="content-result-not-found">Survey not found</div>
      </div>
    );
  }

  if (!loading && !error && (match && match.params && match.params.id)) {
    if (mainPage === 'statistics' || mainPage === 'workspaces') {
      page.push(
        <div className="main-results-container" key="results-page-content">
          {mainPage === 'statistics' && (
            <div className="toolbar-container toolbar-container-relative toolbar-container-no-padding-bottom">
              <div />

              {isAdmin ? (
                <div
                  className={`toolbar-button export-button share-results-button ${
                    linkCopied ? 'share-results-button-tooltip-visible' : ''
                  }`}
                  role="presentation"
                  onClick={e => {
                    if (isAdmin) {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      setShowInsightsSettingsPopup(true);
                    }
                  }}
                >
                  Insights settings
                </div>
              ) : null}

              <div
                className={`toolbar-button export-button share-results-button ${
                  linkCopied ? 'share-results-button-tooltip-visible' : ''
                }`}
                role="presentation"
                onClick={() => {
                  if (checkIfAllCookiesAllowed()) {
                    mixpanel.actions.trackEvent(
                      mixpanel.events.CLIENT_RESULTS_PAGE_CLICK_SHARE_RESULTS,
                      { [mixpanel.eventProperties.SURVEY_ID]: survey.id }
                    );
                  }
                  copyLinkToClipboard(
                    `${window.location.origin.toString()}${survey.viewUrl}`
                  );
                  setLinkCopied(true);
                  setTimeout(() => {
                    setLinkCopied(false);
                  }, '1500');
                }}
              >
                Share results
                <div className="toolbar-temporary-tooltip">Link copied</div>
                <div className="toolbar-temporary-tooltip-arrow" />
                <div className="toolbar-temporary-tooltip-arrow toolbar-temporary-tooltip-arrow-white" />
              </div>
            </div>
          )}
          {showInsightsSettingsPopup ? (
            <InsightSettingsPopup
              survey={survey}
              surveyRefetch={surveyRefetch}
              setShowSettingsPopup={setShowInsightsSettingsPopup}
            />
          ) : null}
          <Scoping
            surveyId={survey.id}
            scoping={survey.scoping || {}}
            recordDetails={survey.recordDetails}
            nextStepPopup={nextStepPopup}
            setNextStepPopup={setNextStepPopup}
            surveyRefetch={surveyRefetch}
            surveyHasInsights={
              survey &&
              survey.insightsData &&
              survey.insightsData.insights &&
              survey.insightsData.insights.length > 0
            }
            allowedToViewInsights={
              insightsPermissions && insightsPermissions.allowedToViewInsights
            }
          />
          {insightsPermissions && insightsPermissions.allowedToViewInsights ? (
            <InsightsHeader
              survey={survey}
              onSetActiveFilters={onSetActiveFilters}
              surveyRefetch={surveyRefetch}
              showAddInsightsPopup={showAddInsightsPopup}
              setShowAddInsightsPopup={setShowAddInsightsPopup}
              activeFilters={activeFilters}
              filtersRelation={filtersRelation}
              onSetFiltersRelation={onSetFiltersRelation}
              sortedQuestions={sortedQuestions}
              resultBlocks={resultBlocks}
              stats={headerStats}
              surveyMergedGraphs={survey.mergedGraphs}
              surveyOpportunityGraphs={survey.opportunityGraphs}
              surveyCustomGraphs={survey.customGraphs}
              surveyConceptTestGraphs={survey.conceptTestGraphs}
              surveyValueSelectionGraphs={survey.valueSelectionGraphs}
              surveyIdeaSelectionGraphs={survey.ideaSelectionGraphs}
              activeInsightId={activeInsightId}
              setActiveInsightId={setActiveInsightId}
              filterCollectDataGroups={collectData}
              activeSorting={activeSorting}
              setActiveSorting={setActiveSorting}
              activeFilteringOnSelection={activeFilteringOnSelection}
              setActiveFilteringOnSelection={setActiveFilteringOnSelection}
              activePresentationMode={activePresentationMode}
              setActivePresentationMode={setActivePresentationMode}
              activeChartView={activeChartView}
              setActiveChartView={setActiveChartView}
              viewToken={viewToken}
              isAdmin={isAdmin}
            />
          ) : null}
          <div
            className={`toolbar-container ${
              mainPage === 'workspaces' ? 'toolbar-container-fixed' : ''
            }`}
          >
            <div className="navigation">
              <div
                className="navigation-item data-title"
                role="presentation"
                onClick={() => {
                  if (mainPage !== 'statistics') setMainPage('statistics');
                }}
              >
                Data
              </div>
            </div>
            {mainPage === 'statistics' && isAdmin && (
              <div
                className={`toolbar-button edit-button ${
                  editGraphsPopup ? 'edit-button-active' : ''
                }`}
                role="presentation"
                onClick={() => {
                  if (checkIfAllCookiesAllowed()) {
                    mixpanel.actions.trackEvent(
                      mixpanel.events.CLIENT_RESULTS_PAGE_CLICK_EDIT_SURVEY,
                      { [mixpanel.eventProperties.SURVEY_ID]: survey.id }
                    );
                  }
                  history.push(`/survey/${match.params.id}/edit`);
                }}
              >
                Edit survey
              </div>
            )}
            {mainPage === 'statistics' ? (
              <div
                className={`toolbar-button logic-jump-button ${
                  mainSection === 'logicmap' ? 'logic-jump-button-active' : ''
                }`}
                role="presentation"
                onClick={() => {
                  if (checkIfAllCookiesAllowed()) {
                    mixpanel.actions.trackEvent(
                      mixpanel.events.CLIENT_RESULTS_PAGE_CLICK_LOGIC_MAP,
                      {
                        [mixpanel.eventProperties.SURVEY_ID]: survey.id
                      }
                    );
                  }
                  setMainSection('logicmap');
                }}
              >
                Logic map
              </div>
            ) : null}

            {mainPage === 'workspaces' ? (
              <div
                className={`toolbar-button edit-workspace-button ${
                  showEditWorkspacePopup ? 'edit-workspace-button-active' : ''
                } ${
                  mainPage === 'workspaces' && !isPremiumClient && !isAdmin
                    ? 'edit-workspace-button-disabled'
                    : ''
                }`}
                onClick={() => {
                  if (
                    !(
                      selectedWorkspace === false ||
                      (!isPremiumClient && !isAdmin)
                    )
                  ) {
                    setShowEditWorkspacePopup(true);
                  } else if (isPremiumClient || isAdmin) {
                    setShowCreateWorkspacePopup(true);
                  }
                }}
                role="presentation"
              >
                Edit workspace
              </div>
            ) : null}
            <div
              className={`toolbar-button export-button ${
                mainPage === 'workspaces' && (!isPremiumClient && !isAdmin)
                  ? 'export-button-disabled'
                  : ''
              }`}
              role="presentation"
              onClick={() => {
                if (
                  !(mainPage === 'workspaces' && (!isPremiumClient && !isAdmin))
                ) {
                  if (checkIfAllCookiesAllowed()) {
                    mixpanel.actions.trackEvent(
                      mixpanel.events.CLIENT_RESULTS_PAGE_CLICK_EXPORT_RESULTS,
                      { [mixpanel.eventProperties.SURVEY_ID]: survey.id }
                    );
                  }
                  setExportPopup(true);
                }
              }}
            >
              Export results
            </div>
            {mainPage === 'statistics' && (
              <div
                className="toolbar-button edit-button"
                role="presentation"
                onClick={() => {
                  if (checkIfAllCookiesAllowed()) {
                    mixpanel.actions.trackEvent(
                      mixpanel.events.CLIENT_CLICK_DECISION_TOOLS,
                      { [mixpanel.eventProperties.SURVEY_ID]: survey.id }
                    );
                  }
                  setEditGraphsPopup(true);
                }}
              >
                Analysis tools
              </div>
            )}
          </div>
          {mainPage === 'statistics' ? (
            <div className="results-content">
              <div
                className={`results-container action-results-column-container ${
                  'IntersectionObserver' in window && mainPage === 'statistics'
                    ? 'action-results-column-container-sticky'
                    : ''
                }`}
              >
                <div className="container-title">
                  <div
                    role="presentation"
                    className={
                      detailsSection === 'demographics'
                        ? 'side-results-container-title-item active'
                        : 'side-results-container-title-item'
                    }
                    onClick={() => {
                      setDetailsSection('demographics');
                    }}
                  >
                    Demographics
                  </div>
                </div>

                {detailsSection === 'demographics' ? (
                  <Demographics
                    results={results}
                    headerStats={headerStats}
                    onToggleFilter={onToggleFilter}
                    filterCollectDataGroups={collectData}
                    activeFilters={activeFilters}
                    unFilteredSideResults={unFilteredSideResults}
                  />
                ) : null}
              </div>
              <div className="blocks-results-wrapper">
                <div
                  ref={blocksResultsHeaderRef}
                  className={`blocks-results-header ${
                    scrollingQuestions ? 'blocks-results-header-shadow' : ''
                  } ${
                    'IntersectionObserver' in window
                      ? 'blocks-results-header-sticky'
                      : ''
                  }`}
                >
                  <div className="content-results-header">
                    <HeaderStats
                      stats={headerStats}
                      campaign={survey}
                      onTimePrettyPrint={onTimePrettyPrint}
                    />
                  </div>
                  <FiltersBar
                    activeFilters={activeFilters}
                    onToggleFilter={onToggleFilter}
                    resultBlocks={resultBlocks}
                    filtersRelation={filtersRelation}
                    nestedFilters={nestedFilters}
                    campaign={survey}
                    sortedQuestions={sortedQuestions}
                    onResetFilters={() => {
                      onResetFilters();
                      setActiveInsightId(null);
                    }}
                    onSetFiltersRelation={onSetFiltersRelation}
                    mutuallyExclusive={
                      !!(headerStats && !headerStats.responses)
                    }
                    removeNestedFilter={removeNestedFilter}
                  />
                </div>

                <div className="blocks-results-column-container">
                  {mainSection === 'summary' && filteredQuestions && survey
                    ? getSortedTemplatesAndQuestionsElements(
                        sortedTemplatesAndQuestions
                      )
                    : null}
                  {editGraphsPopup ? (
                    <Popup
                      component={
                        <EditGraphsPopup
                          surveyId={survey.id}
                          onClose={() => setEditGraphsPopup(false)}
                          questions={filteredQuestions || []}
                          surveyRefetch={surveyRefetch}
                          editGraphsPopup={editGraphsPopup}
                          setEditGraphsPopup={setEditGraphsPopup}
                          hiddenGraphs={survey.surveyHiddenGraphs}
                          mergedGraphs={survey.surveyMergedGraphs}
                          isAdmin={isAdmin}
                          isAllowedToExecuteRequests={
                            !!(isAuthenticated || viewToken)
                          }
                          viewToken={viewToken}
                        />
                      }
                      customStyles={{ padding: 'none' }}
                      onClose={() => setEditGraphsPopup(false)}
                    />
                  ) : null}
                  {mainSection === 'logicmap' ? (
                    <div className="logic-map-container-results-page">
                      <Popup
                        component={
                          <FlowChart
                            width="900"
                            height="100%"
                            onQuestionClick={() => {}}
                            surveyLists={orderQuestionsWithGroupNodes}
                            content={survey.content}
                            questions={questionsMapping}
                            activeFlowId={null}
                          />
                        }
                        onClose={onFlowChartPopupClose}
                      />
                    </div>
                  ) : null}
                  {mainSection === 'responsetimes' ? (
                    <ResponseTimes responseTimes={responseTimes} />
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          {videoPopup ? (
            <Popup
              component={
                <VideoPopup video={videoPopup} setVideoPopup={setVideoPopup} />
              }
              onClose={() => {
                setVideoPopup(null);
              }}
            />
          ) : null}

          {exportPopup ? (
            <Export
              onGenerateCSV={(questionIndices, exportRemovedResponses) => {
                onGenerateCSV(
                  match.params.id,
                  questionIndices,
                  viewToken || null,
                  exportRemovedResponses
                );
              }}
              isAdmin={isAdmin}
              isPremiumClient={isPremiumClient}
              activeSorting={activeSorting}
              setExportPopup={setExportPopup}
              questions={filteredQuestions}
              surveyId={survey.id}
            />
          ) : null}

          {mainPage === 'workspaces' ? (
            <Workspaces
              key="workspaces-container"
              surveyId={match.params.id}
              resultBlocks={resultBlocks}
              stats={headerStats}
              unfilteredResults={unfilteredResults}
              setMainPage={setMainPage}
              showEditWorkspacePopup={showEditWorkspacePopup}
              setShowEditWorkspacePopup={setShowEditWorkspacePopup}
              authorized={isPremiumClient || isAdmin}
              selectedWorkspace={selectedWorkspace}
              setSelectedWorkspace={setSelectedWorkspace}
              showCreateWorkspacePopup={showCreateWorkspacePopup}
              setShowCreateWorkspacePopup={setShowCreateWorkspacePopup}
            />
          ) : null}
        </div>
      );
    }
  }

  return page;
};

export default CampaignResults;
