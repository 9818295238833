import React, { useState } from 'react';

import ResultUnderCategory from './ResultUnderCategory';

import closeIcon from '../../../../../../../../../../../../assets/img/delete.svg';
import dropDownBoldGreyIcon from '../../../../../../../../../../../../assets/img/dropdown-arrow-bold-grey.svg';

import styles from '../GeneratedCategories.module.css';

const GeneratedCategory = props => {
  const {
    category,
    onResultRemoveToggle,
    onCategoryRemoveToggle,
    isParentRemoved,
    removedResults
  } = props;

  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={styles.category} key={category.id}>
      <div className={styles.categoryNameContainer}>
        <div className={styles.categoryName}>
          <span
            className={`${styles.categoryNameText} ${
              isParentRemoved ? styles.deselected : ''
            }`}
          >
            {category.categoryName} ({category.resultsCount})
          </span>
          <img
            src={dropDownBoldGreyIcon}
            alt="Display results"
            className={`${styles.dropDownToolbarIcon} ${
              collapsed ? styles.dropDownToolbarIconInactive : ''
            }`}
            role="presentation"
            onClick={() => {
              setCollapsed(prev => !prev);
            }}
          />
        </div>
        <div className={styles.removeIconContainer}>
          <img
            className={styles.removeIconCategory}
            src={closeIcon}
            alt="Remove category"
            role="presentation"
            onClick={() => onCategoryRemoveToggle(category.id, isParentRemoved)}
          />
        </div>
      </div>
      <div
        className={`${styles.resultsUnderCategoryContainer} ${
          collapsed ? styles.collapsed : ''
        }`}
      >
        {!collapsed && category.results && category.results.length
          ? category.results.map(r => (
              <ResultUnderCategory
                key={r.resultId}
                result={r}
                isRemoved={
                  removedResults &&
                  removedResults[category.id] &&
                  removedResults[category.id].length &&
                  removedResults[category.id].includes(r.resultId)
                }
                onResultRemoveToggle={onResultRemoveToggle}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default GeneratedCategory;
