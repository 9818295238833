export const questionResultsAnalysisPopupActions = {
  CREATE_SUMMARY: 'CREATE_SUMMARY',
  UPDATE_SUMMARY: 'UPDATE_SUMMARY',
  REMOVE_SUMMARY: 'REMOVE_SUMMARY',
  AI_ANALYSIS: 'AI_ANALYSIS'
};

export const aiAnalysisTypes = {
  CATEGORIZE_SUMMARIZE: 'CATEGORIZE_SUMMARIZE',
  SENTIMENT_ANALYSIS: 'SENTIMENT_ANALYSIS'
};
