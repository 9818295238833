import gql from 'graphql-tag';

export const CREATE_INSIGHT = gql`
  mutation CreateInsight(
    $survey: ID!
    $name: String!
    $filters: InsightFiltersInput
    $questions: [InsightQuestionsInput!]
    $questionSettings: InsightQuestionSettingInput
  ) {
    createInsight(
      input: {
        survey: $survey
        name: $name
        filters: $filters
        questions: $questions
        questionSettings: $questionSettings
      }
    ) {
      success
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_INSIGHT = gql`
  mutation UpdateInsight(
    $id: ID!
    $survey: ID!
    $name: String!
    $filters: InsightFiltersInput
    $questions: [InsightQuestionsInput!]
  ) {
    updateInsight(
      input: {
        id: $id
        survey: $survey
        name: $name
        filters: $filters
        questions: $questions
      }
    ) {
      success
      errors {
        type
        message
      }
    }
  }
`;

export const REMOVE_INSIGHT = gql`
  mutation RemoveInsight($id: ID!, $survey: ID!) {
    removeInsight(input: { id: $id, survey: $survey }) {
      success
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_INSIGHTS_ORDER = gql`
  mutation UpdateInsightsOrder($orderedIds: [ID!]!, $survey: ID!) {
    updateInsightsOrder(input: { orderedIds: $orderedIds, survey: $survey }) {
      success
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_INSIGHTS_PERMISSIONS = gql`
  mutation UpdateInsightsPermissions(
    $survey: ID!
    $allowedToCreateInsights: Boolean
    $allowedToViewInsights: Boolean
  ) {
    updateInsightsPermissions(
      input: {
        survey: $survey
        allowedToCreateInsights: $allowedToCreateInsights
        allowedToViewInsights: $allowedToViewInsights
      }
    ) {
      success
      errors {
        type
        message
      }
    }
  }
`;
