import React from 'react';

import styles from '../AiAnswersAnalysis.module.css';

const AiAnswerAnalysisPopup = props => {
  const {
    showAiPopup,
    setShowAiPopup,
    onClickSummarizeCategorize,
    onClickSentimentAnalysis
  } = props;

  if (showAiPopup && showAiPopup.error) {
    const errorReasons = [
      {
        title: 'No results available',
        description:
          'The question may not have any results yet. Please ensure there are results to analyze.'
      },
      {
        title: 'Data issue',
        description:
          'There might be a problem with the data sent to the AI service.'
      },
      {
        title: 'AI service error',
        description:
          'The AI service itself may have experienced an internal processing error.'
      }
    ];

    return (
      <div
        className={styles.popupBackgroundContainer}
        role="presentation"
        onClick={() => {
          setShowAiPopup(null);
        }}
      >
        <div
          className={`${styles.popupContainer} ${styles.popupErrorContainer}`}
          role="presentation"
          onClick={e => e.stopPropagation()}
        >
          <div className={styles.popupContent}>
            <div className={styles.popupTitle}>AI analysis failed</div>
            <div className={styles.popupTextContainer}>
              <div>
                An issue was encountered when analyzing results with AI. Here
                are some possible reasons:
              </div>
              <ul>
                {errorReasons.map(e => (
                  <li key={e.title}>
                    <span className={styles.errorBold}>{e.title}</span>:{' '}
                    {e.description}
                  </li>
                ))}
              </ul>
              <div style={{ marginBottom: '1em' }}>
                <span className={styles.errorBold}>Important</span>: Each
                analysis attempt uses credits. If this error continues, there
                may be a problem with the AI service.
              </div>
              <div>
                <span className={styles.errorBold}>What you can do</span>:
                Verify that results are available for the question. If the
                problem persists, please contact support for assistance.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showAiPopup && showAiPopup.analysisLoading) {
    return (
      <div className={styles.popupBackgroundContainer}>
        <div
          className={`${styles.popupContainer} ${styles.popupLoadingContainer}`}
          role="presentation"
          onClick={e => e.stopPropagation()}
        >
          <div className={styles.popupContent}>
            <div className={styles.popupTitle}>AI analysis loading...</div>

            <div className={styles.aiLoadingInstructions}>
              <p className={styles.warning}>
                <span role="img" aria-label="warning">
                  ⚠️
                </span>{' '}
                Please wait and do not refresh the page, or you will lose access
                to the generated results.
              </p>
            </div>

            <div className={styles.fieldsContainer}>
              <div className={styles.subTitle}>Generating summary</div>
              <div className={styles.dummyTextarea} />
              <div
                className={`${styles.subTitle} ${styles.dummyCategoriesTitle}`}
              >
                Generating categories
              </div>
              <div className={styles.dummyCategories}>
                <div className={styles.dummyCategory} />
                <div className={styles.dummyCategory} />
                <div className={styles.dummyCategory} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles.popupBackgroundContainer}
      role="presentation"
      onClick={() => {
        setShowAiPopup(null);
      }}
    >
      <div
        className={styles.popupContainer}
        role="presentation"
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.popupContent}>
          <div className={styles.popupTitle}>Analyse with AI (BETA)</div>
          <div>What kind of analysis would you like to perform?</div>
          <div className={styles.popupTextContainer}>
            {onClickSummarizeCategorize ? (
              <div
                className={`${styles.button} ${styles.popupButton}`}
                role="presentation"
                onClick={() => {
                  setShowAiPopup(false);
                  onClickSummarizeCategorize();
                }}
              >
                Summarize & categorize
              </div>
            ) : null}
            {onClickSentimentAnalysis ? (
              <div
                className={`${styles.button} ${styles.popupButton} ${
                  styles.disabledPopupButton
                }`}
              >
                Sentiment analysis (coming soon)
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiAnswerAnalysisPopup;
