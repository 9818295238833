import React, { useState } from 'react';

import tickIconWhite from '../../../../../../../assets/img/tick_white.svg';
import dropDownBoldGreyIcon from '../../../../../../../assets/img/dropdown-arrow-bold-grey.svg';

import styles from '../../../Question/Toolbars/Toolbar.module.css';
import AddInsight from '../../../Question/AddInsight/AddInsight';
import { insightQuestionTypes } from '../../../../Insights/helpers/constants';

const GraphActionsToolbar = props => {
  const {
    onEdit,
    onRemove,
    filteringOnSelection,
    setFilteringOnSelection,
    isFiltered,
    setEditBackgroundPopup,
    presentationModeEnabled,
    setPresentationModeEnabled,
    graph,
    setShowAddInsightsPopup,
    additionalInsightProperties,
    allowedToCreateInsights
  } = props;

  const [toolbarActive, setToolbarActive] = useState(false);

  return (
    <>
      <div
        className={`${styles.container} ${
          toolbarActive ? styles.containerActive : ''
        }`}
      >
        <div
          className={`${styles.toolbarItem} ${styles.clickable}`}
          role="presentation"
          onClick={onEdit}
        >
          <span className={styles.itemLabel}>Edit framework</span>
        </div>
        <div
          className={`${styles.toolbarItem} ${styles.clickable}`}
          role="presentation"
          onClick={() => setEditBackgroundPopup(true)}
        >
          <span className={styles.itemLabel}>Edit background colors</span>
        </div>
        <div
          className={`${styles.toolbarItem} ${styles.clickable}`}
          role="presentation"
          onClick={onRemove}
        >
          <span className={styles.itemLabel}>Delete framework</span>
        </div>
        {isFiltered && (
          <div
            className={`${styles.toolbarItem} ${styles.verticalAlign} ${
              styles.clickable
            }`}
            role="presentation"
            onClick={() => {
              setFilteringOnSelection(!filteringOnSelection);
            }}
          >
            <div
              className={`${styles.visibleBox} ${
                !filteringOnSelection ? styles.visibleBoxActive : null
              }`}
            >
              <img
                src={tickIconWhite}
                className={styles.visibleTick}
                alt="Tick"
              />
            </div>
            <span className={styles.itemLabel}>Compare to total sample</span>
          </div>
        )}
        <div
          className={`${styles.toolbarItem} ${styles.verticalAlign} ${
            styles.clickable
          }`}
          role="presentation"
          onClick={() => {
            setPresentationModeEnabled(!presentationModeEnabled);
          }}
        >
          <div
            className={`${styles.visibleBox} ${
              presentationModeEnabled ? styles.visibleBoxActive : null
            }`}
          >
            <img
              src={tickIconWhite}
              className={styles.visibleTick}
              alt="Tick"
            />
          </div>
          <span className={styles.itemLabel}>Presentation mode</span>
        </div>
        {allowedToCreateInsights ? (
          <AddInsight
            setShowAddInsightsPopup={setShowAddInsightsPopup}
            questionId={graph.id}
            type={insightQuestionTypes.TEMPLATE}
            additionalInsightProperties={additionalInsightProperties}
            allowedToCreateInsights={allowedToCreateInsights}
          />
        ) : null}
      </div>
      <img
        src={dropDownBoldGreyIcon}
        alt="Display toolbar"
        className={`${styles.dropDownToolbarIcon} ${
          !toolbarActive ? styles.dropDownToolbarIconInactive : ''
        }`}
        role="presentation"
        onClick={() => {
          setToolbarActive(!toolbarActive);
        }}
      />
    </>
  );
};

export default GraphActionsToolbar;
