import React, { useState, useEffect } from 'react';
import styles from './OpenQuestion.module.css';
import CategoryList from './CategoryList/CategoryList';
import CategoriesDistribution from './CategoryList/CategoriesDistribution/CategoriesDistribution';
import QuestionSummary from './ResultsAnalysis/components/QuestionSummary/QuestionSummary';

import Icon from '../../../../Icon';
import tickIcon from '../../../../../../assets/img/tick_white.svg';
import heartIcon from '../../../../../../assets/img/heart-purple.svg';

const OpenQuestion = props => {
  const {
    campaign,
    block,
    onTimePrettyPrint,
    onAssignCategoresToOpenAnswer,
    onToggleFilter,
    activeFilters,
    expandOpenQuestion,
    answerSearch,
    setAnswerSearch,
    selectAllOpenQuestionCategories,
    setSelectAllOpenQuestionCategories,
    categories,
    isAdmin,
    isAllowedToExecuteRequests,
    filteringOnSelectionEnabled,
    openAnswerCategoryFiltering,
    viewToken
  } = props;

  const [categoryResultsCount, setCategoryResultsCount] = useState({});
  const [
    categoryUnfilteredResultsCount,
    setCategoryUnfilteredResultsCount
  ] = useState({});
  const [
    selectedOpenQuestionAnswers,
    setSelectedOpenQuestionAnswers
  ] = useState([]);
  const [showCategoriesDropdown, setShowCategoriesDropdown] = useState(false);
  const [categoryOverflow, setCategoryOverflow] = useState({
    overflow: false,
    text: '',
    x: 0,
    y: 0
  });

  useEffect(
    () => {
      if (block.unfilteredResults && block.unfilteredResults.length) {
        const categoryUnfilteredResults = {};
        block.unfilteredResults.forEach(result => {
          if (result.categories && result.categories.length) {
            result.categories.forEach(categoryToAdd => {
              if (
                categoryUnfilteredResults[categoryToAdd] ||
                categoryUnfilteredResults[categoryToAdd] === 0
              ) {
                categoryUnfilteredResults[categoryToAdd] += 1;
              } else {
                categoryUnfilteredResults[categoryToAdd] = 1;
              }
            });
          }
        });
        setCategoryUnfilteredResultsCount(categoryUnfilteredResults);
      }
    },
    [block.unfilteredResults]
  );

  useEffect(
    () => {
      if (block.results && block.results.length && categories) {
        const categoryResults = {};
        block.results.forEach(result => {
          if (result.categories && result.categories.length) {
            result.categories.forEach(categoryToAdd => {
              if (
                categoryResults[categoryToAdd] ||
                categoryResults[categoryToAdd] === 0
              ) {
                categoryResults[categoryToAdd] += 1;
              } else {
                categoryResults[categoryToAdd] = 1;
              }
            });
          }
        });
        setCategoryResultsCount(categoryResults);
      }
    },
    [block.results]
  );

  const isAnswerSelected = (blockId, resultId) => {
    const answer = selectedOpenQuestionAnswers.filter(
      selectedAnswer =>
        selectedAnswer.blockId === blockId &&
        selectedAnswer.resultId === resultId
    );

    if (answer && answer.length) return true;
    return false;
  };

  const onSelectCategoriesCompleted = selectedCategories => {
    const categorization = selectedOpenQuestionAnswers.map(answer => {
      const allCategories = [...answer.categories, ...selectedCategories];
      const newCategories = allCategories.filter(
        (item, index) => allCategories.indexOf(item) === index
      );

      return {
        resultId: answer.resultId,
        blockId: answer.blockId,
        categories: newCategories
      };
    });

    assignCategoresToOpenAnswer(categorization);
    setSelectAllOpenQuestionCategories(false);
  };

  const onSingleAnswerCategoryRemove = (
    result,
    blockId,
    assignedCategories,
    categoryName
  ) => {
    const newAnswerCategories = assignedCategories.filter(
      newCategory => newCategory !== categoryName
    );
    const categorization = [
      {
        resultId: result,
        blockId,
        categories: newAnswerCategories
      }
    ];

    assignCategoresToOpenAnswer(categorization);
  };

  const onCategoryRemoveFromAnswers = category => {
    const categorization = [];

    block.results.forEach(result => {
      if (result.categories && result.categories.length) {
        if (result.categories.indexOf(category) > -1) {
          const newAnswerCategories = result.categories.filter(
            newCategory => newCategory !== category
          );

          categorization.push({
            resultId: result.resultId,
            blockId: result.block,
            categories: newAnswerCategories
          });
        }
      }
    });

    assignCategoresToOpenAnswer(categorization);
  };

  const onCategoryRenameFromAnswers = (oldCategory, newCategory) => {
    const categorization = [];

    block.results.forEach(result => {
      if (result.categories && result.categories.length) {
        if (result.categories.indexOf(oldCategory) > -1) {
          const newAnswerCategories = result.categories.map(c => {
            if (c === oldCategory) return newCategory;
            return c;
          });

          categorization.push({
            resultId: result.resultId,
            blockId: result.block,
            categories: newAnswerCategories
          });
        }
      }
    });

    assignCategoresToOpenAnswer(categorization);
  };

  const assignCategoresToOpenAnswer = categorization => {
    setSelectedOpenQuestionAnswers([]);
    setShowCategoriesDropdown(false);
    if (isAllowedToExecuteRequests) {
      onAssignCategoresToOpenAnswer(campaign.id, categorization, viewToken);
    }
  };

  const toggleSelectAll = () => {
    if (selectAllOpenQuestionCategories) {
      setSelectAllOpenQuestionCategories(false);
      setSelectedOpenQuestionAnswers([]);
    } else {
      setSelectAllOpenQuestionCategories(true);
      setSelectedOpenQuestionAnswers(
        block.results
          .filter(
            a => !answerSearch || searchAnswer(a.answer_string.toLowerCase())
          )
          .map(answer => ({
            blockId: answer.block,
            resultId: answer.resultId,
            categories: answer.categories ? answer.categories : [],
            answer_string: answer.answer_string,
            rewardDetails: answer.rewardDetails
          }))
      );
    }
  };

  const searchAnswer = openQuestionAnswerString => {
    const searchInputArr = answerSearch.toLowerCase().split(';');
    return searchInputArr.some(searchValue =>
      openQuestionAnswerString.includes(searchValue.trim())
    );
  };

  const findClosestActiveIndex = i =>
    block.results
      .reduce((acc, curr, index) => {
        if (isAnswerSelected(curr.block, curr.resultId)) {
          acc.push(index);
        }
        return acc;
      }, [])
      .reduce((acc, curr) =>
        Math.abs(curr - i) < Math.abs(acc - i) ? curr : acc
      );

  if (block.results && block.results.length) {
    return (
      <div className={styles.openQuestionContainer}>
        <QuestionSummary
          surveyId={campaign.id}
          questionId={block.id}
          resultsAnalysisData={campaign.resultsAnalysisData || {}}
        />
        {categories && categories.length > 0 ? (
          <div
            className={`${
              styles.openQuestionDistributionContainer
            } open-question-answers-container`}
          >
            <CategoriesDistribution
              categories={categories}
              categoryResultsCount={categoryResultsCount}
              categoryUnfilteredResultsCount={
                !openAnswerCategoryFiltering
                  ? categoryUnfilteredResultsCount
                  : false
              }
              onToggleFilter={onToggleFilter}
              block={block}
              activeFilters={activeFilters}
              filteringOnSelectionEnabled={filteringOnSelectionEnabled}
            />
          </div>
        ) : null}
        <CategoryList
          categories={categories}
          onSelectCategoriesCompleted={onSelectCategoriesCompleted}
          selectedOpenQuestionAnswers={selectedOpenQuestionAnswers}
          onCategoryRemoveFromAnswers={onCategoryRemoveFromAnswers}
          onCategoryRenameFromAnswers={onCategoryRenameFromAnswers}
          showCategoriesDropdown={showCategoriesDropdown}
          setShowCategoriesDropdown={setShowCategoriesDropdown}
          block={block}
          activeFilters={activeFilters}
          onToggleFilter={onToggleFilter}
          categoryResultsCount={categoryResultsCount}
          answerSearch={answerSearch}
          setAnswerSearch={setAnswerSearch}
          selectAllOpenQuestionCategories={selectAllOpenQuestionCategories}
          setSelectAll={toggleSelectAll}
          campaignId={campaign.id}
          isAdmin={isAdmin}
        />
        <div
          className={`${
            styles.openQuestionAnswersContainer
          } open-question-answers-container ${
            expandOpenQuestion ? 'open-question-answers-expanded' : ''
          }`}
        >
          {block.results
            .filter(
              a =>
                !answerSearch ||
                (a.answer_string && searchAnswer(a.answer_string.toLowerCase()))
            )
            .map((answer, i) => (
              <div
                className={`${
                  styles.openQuestionAnswerContainer
                } category-name-answer-container`}
                key={answer.resultId}
              >
                <div className={styles.selectIconContainer}>
                  <div
                    role="presentation"
                    className={`${styles.visibleBox} ${
                      isAnswerSelected(answer.block, answer.resultId)
                        ? styles.active
                        : null
                    }`}
                    onClick={e => {
                      if (isAnswerSelected(answer.block, answer.resultId)) {
                        setSelectAllOpenQuestionCategories(false);
                        const filteredSelectedAnswers = selectedOpenQuestionAnswers.filter(
                          selectedAnswer =>
                            selectedAnswer.block !== answer.block &&
                            selectedAnswer.resultId !== answer.resultId
                        );
                        setSelectedOpenQuestionAnswers(filteredSelectedAnswers);
                      } else if (
                        selectedOpenQuestionAnswers.length + 1 >=
                        block.results.length
                      ) {
                        setSelectAllOpenQuestionCategories(true);
                      } else if (
                        e.shiftKey &&
                        selectedOpenQuestionAnswers &&
                        selectedOpenQuestionAnswers.length
                      ) {
                        const closestActiveIndex = findClosestActiveIndex(i);
                        let min = i;
                        let max = closestActiveIndex - 1;
                        if (i > closestActiveIndex) {
                          min = closestActiveIndex + 1;
                          max = i;
                        }
                        const answersInRange = block.results.reduce(
                          (acc, curr, index) => {
                            if (index >= min && index <= max) {
                              acc.push({
                                blockId: curr.block,
                                resultId: curr.resultId,
                                categories: curr.categories
                                  ? curr.categories
                                  : [],
                                answer_string: curr.answer_string,
                                rewardDetails: curr.rewardDetails
                              });
                            }
                            return acc;
                          },
                          []
                        );
                        const newSelectedAnswers = [
                          ...selectedOpenQuestionAnswers,
                          ...answersInRange
                        ];
                        setSelectedOpenQuestionAnswers(newSelectedAnswers);
                      } else {
                        setSelectedOpenQuestionAnswers([
                          ...selectedOpenQuestionAnswers,
                          {
                            blockId: answer.block,
                            resultId: answer.resultId,
                            categories: answer.categories
                              ? answer.categories
                              : [],
                            answer_string: answer.answer_string,
                            rewardDetails: answer.rewardDetails
                          }
                        ]);
                      }
                    }}
                  >
                    <img
                      src={tickIcon}
                      className={styles.visibleTick}
                      alt="Tick"
                    />
                  </div>
                </div>
                <div
                  className={`${
                    styles.answerText
                  } category-name-answer-string-container`}
                >
                  <span className={styles.categoryNameAnswerString}>
                    {answer.answer_string}
                  </span>
                  {answer.categories
                    ? answer.categories.map((category, index) => (
                        <span
                          className={`${
                            styles.categoryName
                          } category-name-single-label`}
                          key={`category-name-${answer.resultId.toString()}-${index.toString()}`}
                          onMouseEnter={e => {
                            const bounds = e.target.getBoundingClientRect();
                            if (e.currentTarget.offsetWidth > 130) {
                              setCategoryOverflow({
                                overflow: true,
                                text: category,
                                x: bounds.x + bounds.width - 35,
                                y: bounds.y - bounds.height - 5
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            if (categoryOverflow.overflow) {
                              setCategoryOverflow({
                                ...categoryOverflow,
                                overflow: false
                              });
                            }
                          }}
                        >
                          <span className={styles.categoryNameLabel}>
                            <span className={styles.categoryText}>
                              {category}
                            </span>
                            <span className={styles.categoryCount}>
                              ({categoryResultsCount[category] || 0})
                            </span>
                          </span>
                          <span
                            className={`${
                              styles.categorySingleAnswerRemove
                            } category-single-answer-remove`}
                            role="presentation"
                            onClick={() => {
                              onSingleAnswerCategoryRemove(
                                answer.resultId,
                                answer.block,
                                answer.categories,
                                category
                              );
                            }}
                          >
                            <Icon type="close" style={{ color: '#5200f1' }} />
                          </span>
                        </span>
                      ))
                    : null}
                </div>
                {categoryOverflow && categoryOverflow.overflow ? (
                  <span
                    className={styles.categoryOverflowInfo}
                    style={{
                      top: `${categoryOverflow.y}px`,
                      left: `${categoryOverflow.x}px`
                    }}
                  >
                    {categoryOverflow.text}
                  </span>
                ) : null}
                <div className={styles.answerTimeTaken}>
                  {answer &&
                  answer.rewardDetails &&
                  answer.rewardDetails.rewardType ? (
                    <img
                      src={heartIcon}
                      className={styles.heartIcon}
                      alt="Heart"
                    />
                  ) : null}
                  {onTimePrettyPrint(answer.timeTaken)}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return <div className="no-chart-data">No data</div>;
};

export default OpenQuestion;
