import React, { useState } from 'react';

import Insights from './components/Insights/Insights';

import styles from './InsightsHeader.module.css';
import InsightsPopup from '../InsightsPopup/InsightsPopup';
import { insightQuestionTypes } from '../../helpers/constants';

import resultsPageInsightsIcon from '../../../../../../assets/img/results-page-insights-icon.svg';
import arrowGrey from '../../../../../../assets/img/arrow-down.svg';

export default ({
  survey,
  onSetActiveFilters,
  surveyRefetch,
  showAddInsightsPopup,
  setShowAddInsightsPopup,
  activeFilters,
  filtersRelation,
  onSetFiltersRelation,
  sortedQuestions,
  resultBlocks,
  stats,
  surveyMergedGraphs,
  surveyOpportunityGraphs,
  surveyCustomGraphs,
  surveyConceptTestGraphs,
  surveyValueSelectionGraphs,
  surveyIdeaSelectionGraphs,
  activeInsightId,
  setActiveInsightId,
  filterCollectDataGroups,
  activeSorting,
  setActiveSorting,
  activeFilteringOnSelection,
  setActiveFilteringOnSelection,
  activePresentationMode,
  setActivePresentationMode,
  activeChartView,
  setActiveChartView,
  viewToken
}) => {
  const [internalData, setInternalData] = useState({});
  const [isExpanded, setIsExpanded] = useState(true);

  const haveInsights =
    survey &&
    survey.insightsData &&
    survey.insightsData.insights &&
    survey.insightsData.insights.length;

  const onEditInsightClick = insight => {
    setInternalData(JSON.parse(JSON.stringify(insight)));
    setShowAddInsightsPopup({
      insightId: insight._id
    });
  };

  const parseQuestion = question => {
    let questionValue;
    try {
      questionValue = JSON.parse(question)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n');
    } catch (e) {
      questionValue = question;
    }
    return questionValue;
  };

  const getQuestionIcon = block => {
    let icon = block.name;

    if (block.name === 'checkboxes') icon = 'multiple-choice';
    else if (block.show_selected_answers_order) icon = 'ranking';
    else if (block.isCommunityAssignQuestion) icon = 'addToCommunity';

    return icon;
  };

  const getRichTextString = blockQuestion => {
    let question;
    try {
      question = JSON.parse(blockQuestion)
        .blocks.map(draftBlock => draftBlock.text)
        .join(' ');
    } catch (error) {
      question = blockQuestion;
    }
    return question;
  };

  const getSurveyGraphs = s => {
    let graphs = [];
    const addGraphsToOptions = graph => {
      if (graph && graph.length) {
        graphs = [
          ...graphs,
          ...graph.map(g => ({
            ...g,
            id: g.id,
            question: g.name,
            optionType: insightQuestionTypes.TEMPLATE,
            icon: 'merged-graph'
          }))
        ];
      }
    };

    addGraphsToOptions(s.mergedGraphs);
    addGraphsToOptions(s.opportunityGraphs);
    addGraphsToOptions(s.customGraphs);
    addGraphsToOptions(s.conceptTestGraphs);
    addGraphsToOptions(s.valueSelectionGraphs);
    addGraphsToOptions(s.ideaSelectionGraphs);

    return graphs;
  };

  const surveyGraphs = getSurveyGraphs(survey);

  return (
    <>
      {haveInsights ? (
        <div className={styles.container}>
          <div
            className={
              isExpanded
                ? styles.title
                : `${styles.title} ${styles.collapsedTitle}`
            }
            role="presentation"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <img
              src={resultsPageInsightsIcon}
              className={styles.insightsIcon}
              alt="Insights icon"
            />
            Insights
            <img
              src={arrowGrey}
              className={
                isExpanded
                  ? styles.arrowIcon
                  : `${styles.arrowIcon} ${styles.rotatedArrow}`
              }
              alt="Expand-colapse icon"
            />
          </div>

          {isExpanded ? (
            <Insights
              survey={survey}
              onSetActiveFilters={onSetActiveFilters}
              activeInsightId={activeInsightId}
              setActiveInsightId={setActiveInsightId}
              surveyRefetch={surveyRefetch}
              onEditClick={onEditInsightClick}
              filterCollectDataGroups={filterCollectDataGroups}
              filtersRelation={filtersRelation}
              onSetFiltersRelation={onSetFiltersRelation}
              sortedQuestions={sortedQuestions}
              surveyMergedGraphs={surveyMergedGraphs}
              surveyOpportunityGraphs={surveyOpportunityGraphs}
              surveyCustomGraphs={surveyCustomGraphs}
              surveyConceptTestGraphs={surveyConceptTestGraphs}
              surveyValueSelectionGraphs={surveyValueSelectionGraphs}
              surveyIdeaSelectionGraphs={surveyIdeaSelectionGraphs}
              getQuestionIcon={getQuestionIcon}
              surveyGraphs={surveyGraphs}
              setActiveSorting={setActiveSorting}
              setActiveFilteringOnSelection={setActiveFilteringOnSelection}
              setActivePresentationMode={setActivePresentationMode}
              setActiveChartView={setActiveChartView}
              viewToken={viewToken}
              parseQuestion={parseQuestion}
            />
          ) : (
            <div />
          )}
        </div>
      ) : null}
      {showAddInsightsPopup ? (
        <InsightsPopup
          survey={survey}
          internalData={internalData}
          setInternalData={setInternalData}
          surveyRefetch={surveyRefetch}
          activeFilters={activeFilters}
          filtersRelation={filtersRelation}
          sortedQuestions={sortedQuestions}
          resultBlocks={resultBlocks}
          showAddInsightsPopup={showAddInsightsPopup}
          setShowAddInsightsPopup={setShowAddInsightsPopup}
          stats={stats}
          surveyMergedGraphs={surveyMergedGraphs}
          surveyOpportunityGraphs={surveyOpportunityGraphs}
          surveyCustomGraphs={surveyCustomGraphs}
          surveyConceptTestGraphs={surveyConceptTestGraphs}
          surveyValueSelectionGraphs={surveyValueSelectionGraphs}
          surveyIdeaSelectionGraphs={surveyIdeaSelectionGraphs}
          parseQuestion={parseQuestion}
          getQuestionIcon={getQuestionIcon}
          surveyGraphs={surveyGraphs}
          getSurveyGraphs={getSurveyGraphs}
          getRichTextString={getRichTextString}
          activeSorting={activeSorting}
          activeFilteringOnSelection={activeFilteringOnSelection}
          activePresentationMode={activePresentationMode}
          activeChartView={activeChartView}
          viewToken={viewToken}
        />
      ) : null}
    </>
  );
};
