import gql from 'graphql-tag';

export const CREATE_QUESTION_SUMMARY = gql`
  mutation CreateQuestionSummary(
    $survey: ID!
    $question: ID!
    $description: String!
  ) {
    createQuestionSummary(
      input: { survey: $survey, question: $question, description: $description }
    ) {
      id
      success
      errors {
        type
        message
      }
    }
  }
`;

export const UPDATE_QUESTION_SUMMARY = gql`
  mutation UpdateQuestionSummary(
    $id: ID!
    $survey: ID!
    $description: String!
  ) {
    updateQuestionSummary(
      input: { id: $id, survey: $survey, description: $description }
    ) {
      success
      errors {
        type
        message
      }
    }
  }
`;

export const REMOVE_QUESTION_SUMMARY = gql`
  mutation RemoveQuestionSummary($id: ID!, $survey: ID!) {
    removeQuestionSummary(input: { id: $id, survey: $survey }) {
      success
      errors {
        type
        message
      }
    }
  }
`;

export const REQUEST_GEMINI_OPEN_ANSWERS_ANALYSIS = gql`
  mutation RequestGeminiOpenAnswersAnalysis(
    $analysisType: AiAnalysisType!
    $survey: ID!
    $question: ID!
    $userResponses: [UserResponseInput!]
  ) {
    requestGeminiOpenAnswersAnalysis(
      input: {
        analysisType: $analysisType
        survey: $survey
        question: $question
        userResponses: $userResponses
      }
    ) {
      id
      success
      errors {
        type
        message
      }
    }
  }
`;

export const GET_GEMINI_OPEN_ANSWERS_ANALYSIS_DATA = gql`
  query GetGeminiOpenAnswersAnalysisData($id: ID!) {
    getGeminiOpenAnswersAnalysisData(input: { id: $id }) {
      success
      summary
      categories {
        categoryName
        resultIds
      }
      errors {
        type
        message
      }
    }
  }
`;

export const SAVE_GEMINI_OPEN_ANSWERS_ANALYSIS_DATA = gql`
  mutation SaveGeminiOpenAnswersAnalysisData(
    $analysisType: AiAnalysisType!
    $analysisId: ID!
    $survey: ID!
    $question: ID!
    $summary: SummaryInput
    $categorization: [CategorizationInput]
  ) {
    saveGeminiOpenAnswersAnalysisData(
      input: {
        analysisType: $analysisType
        analysisId: $analysisId
        survey: $survey
        question: $question
        summary: $summary
        categorization: $categorization
      }
    ) {
      success
      pending
      errors {
        type
        message
      }
    }
  }
`;

export const CONFIRM_SAVE_GEMINI_OPEN_ANSWERS_ANALYSIS_DATA = gql`
  query ConfirmSaveGeminiOpenAnswersAnalysisData(
    $analysisId: ID!
    $survey: ID!
  ) {
    confirmSaveGeminiOpenAnswersAnalysisData(
      input: { analysisId: $analysisId, survey: $survey }
    ) {
      analysisDataSaved
      errors {
        type
        message
      }
    }
  }
`;
