export default (categories, results) =>
  categories && categories.length
    ? categories.map((c, categoryIndex) => {
        const categoryId = `${c.categoryName}-${categoryIndex}`;

        const reducedResults =
          c.resultIds && c.resultIds.length
            ? c.resultIds.reduce((mappedResults, rId) => {
                const resultObject =
                  results && results.length
                    ? results.find(r => r.resultId === rId)
                    : null;

                if (resultObject) {
                  return [
                    ...mappedResults,
                    {
                      ...resultObject,
                      parentId: categoryId
                    }
                  ];
                }

                return mappedResults;
              }, [])
            : [];

        return {
          categoryName: c.categoryName,
          id: categoryId,
          resultsCount: reducedResults ? reducedResults.length : 0,
          results: reducedResults
        };
      })
    : [];
