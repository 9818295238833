import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';

import {
  CREATE_QUESTION_SUMMARY,
  UPDATE_QUESTION_SUMMARY,
  REMOVE_QUESTION_SUMMARY,
  SAVE_GEMINI_OPEN_ANSWERS_ANALYSIS_DATA,
  CONFIRM_SAVE_GEMINI_OPEN_ANSWERS_ANALYSIS_DATA
} from '../../../../../../../../../graphql/ResultsAnalysis';

import useLazyQuery from '../../../../../../../../../surveys/hooks/useLazyQuery';

import { questionResultsAnalysisPopupActions } from '../../../helpers/constants';

import InsightsTextarea from '../../../../../../Insights/components/InsightsPopup/components/InsightsTextarea/InsightsTextarea';
import Loader from '../../../../../../../Loader/Loader';
import GeneratedCategories from './components/GeneratedCategories/GeneratedCategories';

import populateGeneratedCategories from './helpers/populateGeneratedCategories';
import prepareCategoriesForSubmit from './helpers/prepareCategoriesForSubmit';

import tickIcon from '../../../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../../../assets/img/delete.svg';

import styles from './ResultsAnalysisPopup.module.css';

const ResultsAnalysisPopup = props => {
  const {
    showResultsAnalysisPopup,
    setShowResultsAnalysisPopup,
    surveyId,
    questionId,
    setQuestionSummary,
    results = []
  } = props;

  const [internalData, setInternalData] = useState({
    ...(showResultsAnalysisPopup.id ? { id: showResultsAnalysisPopup.id } : {}),
    description: showResultsAnalysisPopup.description || ''
  });
  const [error, setError] = useState(null);
  const [bodyOverflowHidden, setBodyOverflowHidden] = useState(false);
  const [removedResults, setRemovedResults] = useState({});
  const [populatedCategories] = useState(
    populateGeneratedCategories(
      showResultsAnalysisPopup && showResultsAnalysisPopup.categories
        ? showResultsAnalysisPopup.categories
        : [],
      results
    )
  );
  const [startSaveGeminiData, setStartSaveGeminiData] = useState(false);
  const [pendingRequest, setPendingRequest] = useState(null);
  const [timerValue, setTimerValue] = useState(0);
  const confirmTimer = useRef();

  if (!bodyOverflowHidden) {
    document.body.style.overflow = 'hidden';
    setBodyOverflowHidden(true);
  }

  const closePopup = () => {
    document.body.style.removeProperty('overflow');
    setShowResultsAnalysisPopup(false);
    setInternalData({});
  };

  const [
    createQuestionSummary,
    { loading: isCreateQuestionSummaryLoading }
  ] = useMutation(CREATE_QUESTION_SUMMARY, {
    onCompleted: r => {
      if (
        r &&
        r.createQuestionSummary &&
        r.createQuestionSummary.errors &&
        r.createQuestionSummary.errors.length
      ) {
        setError('Creating summary failed');
      } else {
        setQuestionSummary(prev => ({
          ...prev,
          _id:
            (r && r.createQuestionSummary && r.createQuestionSummary.id) ||
            null,
          description: internalData.description
        }));

        closePopup();
      }
    },
    onError: () => setError('Creating summary failed')
  });

  const [
    updateQuestionSummary,
    { loading: isUpdateQuestionSummaryLoading }
  ] = useMutation(UPDATE_QUESTION_SUMMARY, {
    onCompleted: r => {
      if (
        r &&
        r.updateQuestionSummary &&
        r.updateQuestionSummary.errors &&
        r.updateQuestionSummary.errors.length
      ) {
        setError('Updating summary failed');
      } else {
        setQuestionSummary(prev => ({
          ...prev,
          description: internalData.description
        }));

        closePopup();
      }
    },
    onError: () => setError('Updating summary failed')
  });

  const [
    removeQuestionSummary,
    { loading: isRemoveQuestionSummaryLoading }
  ] = useMutation(REMOVE_QUESTION_SUMMARY, {
    onCompleted: r => {
      if (
        r &&
        r.removeQuestionSummary &&
        r.removeQuestionSummary.errors &&
        r.removeQuestionSummary.errors.length
      ) {
        setError('Removing summary failed');
      } else {
        setQuestionSummary(null);

        closePopup();
      }
    },
    onError: () => setError('Removing summary failed')
  });

  const checkAnalysisDataSaved = async () => {
    const res = await confirmSaveGeminiOpenAnswersAnalysisData({
      variables: {
        analysisId: showResultsAnalysisPopup.analysisId,
        survey: surveyId
      }
    });

    if (timerValue > 180) {
      if (confirmTimer && confirmTimer.current) {
        clearTimeout(confirmTimer.current);
      }
    } else if (
      res &&
      res.data &&
      res.data.confirmSaveGeminiOpenAnswersAnalysisData &&
      !res.data.confirmSaveGeminiOpenAnswersAnalysisData.analysisDataSaved
    ) {
      // Polling every 2s
      confirmTimer.current = setTimeout(() => {
        checkAnalysisDataSaved();
      }, 2000);
      setTimerValue(prev => prev + 2);
    } else if (
      res &&
      res.data &&
      res.data.confirmSaveGeminiOpenAnswersAnalysisData &&
      res.data.confirmSaveGeminiOpenAnswersAnalysisData.analysisDataSaved
    ) {
      // Saving categories succesful - reload page
      if (confirmTimer && confirmTimer.current) {
        clearTimeout(confirmTimer.current);
      }

      setQuestionSummary(null);

      closePopup();
      window.location.reload();
    } else if (
      !res ||
      res.errors ||
      (res &&
        res.data &&
        res.data.confirmSaveGeminiOpenAnswersAnalysisData &&
        res.data.confirmSaveGeminiOpenAnswersAnalysisData.errors &&
        res.data.confirmSaveGeminiOpenAnswersAnalysisData.errors.length)
    ) {
      if (confirmTimer && confirmTimer.current) {
        clearTimeout(confirmTimer.current);
      }
    }
  };

  const [
    saveGeminiOpenAnswersAnalysisData,
    { loading: saveGeminiOpenAnswersAnalysisDataLoading }
  ] = useMutation(SAVE_GEMINI_OPEN_ANSWERS_ANALYSIS_DATA, {
    onCompleted: r => {
      if (
        r &&
        r.saveGeminiOpenAnswersAnalysisData &&
        r.saveGeminiOpenAnswersAnalysisData.errors &&
        r.saveGeminiOpenAnswersAnalysisData.errors.length
      ) {
        setStartSaveGeminiData(false);
        setError('Saving AI analysis failed');
      } else if (
        r &&
        r.saveGeminiOpenAnswersAnalysisData &&
        r.saveGeminiOpenAnswersAnalysisData.pending
      ) {
        setPendingRequest({
          pending: true,
          message: 'Categorizing user responses...'
        });

        // Start fetching after 2s
        setTimeout(() => {
          checkAnalysisDataSaved();
        }, 2000);
      } else {
        setQuestionSummary(null);

        closePopup();
        window.location.reload();
      }
    },
    onError: () => {
      setStartSaveGeminiData(false);
      setError('Saving AI analysis failed');
    }
  });

  const confirmSaveGeminiOpenAnswersAnalysisData = useLazyQuery(
    CONFIRM_SAVE_GEMINI_OPEN_ANSWERS_ANALYSIS_DATA,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const isLoading =
    isCreateQuestionSummaryLoading ||
    isUpdateQuestionSummaryLoading ||
    isRemoveQuestionSummaryLoading ||
    saveGeminiOpenAnswersAnalysisDataLoading ||
    startSaveGeminiData;

  const onConfirmClick = async () => {
    if (
      showResultsAnalysisPopup.action ===
      questionResultsAnalysisPopupActions.UPDATE_SUMMARY
    ) {
      const data = {
        id: internalData.id,
        survey: surveyId,
        question: questionId,
        description: internalData.description
      };

      await updateQuestionSummary({
        variables: data
      });
    } else if (
      showResultsAnalysisPopup.action ===
      questionResultsAnalysisPopupActions.REMOVE_SUMMARY
    ) {
      const data = {
        id: internalData.id,
        survey: surveyId
      };

      await removeQuestionSummary({
        variables: data
      });
    } else if (
      showResultsAnalysisPopup.action ===
      questionResultsAnalysisPopupActions.CREATE_SUMMARY
    ) {
      const data = {
        survey: surveyId,
        question: questionId,
        description: internalData.description
      };

      await createQuestionSummary({
        variables: data
      });
    } else if (
      showResultsAnalysisPopup.action ===
      questionResultsAnalysisPopupActions.AI_ANALYSIS
    ) {
      const categorization = prepareCategoriesForSubmit(
        populatedCategories,
        removedResults
      );

      const data = {
        analysisType: showResultsAnalysisPopup.analysisType,
        analysisId: showResultsAnalysisPopup.analysisId,
        survey: surveyId,
        question: questionId,
        summary: {
          description: internalData.description
        },
        categorization
      };

      setStartSaveGeminiData(true);
      await saveGeminiOpenAnswersAnalysisData({
        variables: data
      });
    } else {
      closePopup();
    }
  };

  const getRichTextString = question => {
    let questionValue;
    try {
      questionValue = JSON.parse(question)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n');
    } catch (e) {
      questionValue = question;
    }
    return questionValue;
  };

  const isValidToComplete = () => {
    if (
      showResultsAnalysisPopup.action ===
        questionResultsAnalysisPopupActions.REMOVE_SUMMARY ||
      showResultsAnalysisPopup.action ===
        questionResultsAnalysisPopupActions.AI_ANALYSIS
    ) {
      return true;
    }
    const descriptionRichText =
      internalData &&
      internalData.description &&
      getRichTextString(internalData.description);

    return descriptionRichText && descriptionRichText !== '';
  };

  let title = 'Write summary';
  if (
    showResultsAnalysisPopup.action ===
    questionResultsAnalysisPopupActions.UPDATE_SUMMARY
  ) {
    title = 'Edit summary';
  } else if (
    showResultsAnalysisPopup.action ===
    questionResultsAnalysisPopupActions.REMOVE_SUMMARY
  ) {
    title = 'Remove summary';
  } else if (
    showResultsAnalysisPopup.action ===
    questionResultsAnalysisPopupActions.AI_ANALYSIS
  ) {
    title = 'Summarize & categorize';
  }

  return (
    <div className={styles.backgroundContainer}>
      <div
        className={styles.popupContainer}
        role="presentation"
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.popupContent}>
          <div className={styles.title}>{title}</div>
          {showResultsAnalysisPopup.action ===
          questionResultsAnalysisPopupActions.REMOVE_SUMMARY ? (
            <div className={styles.removeContainer}>
              Are you sure you want to remove this summary?
            </div>
          ) : (
            <div className={styles.fieldsContainer}>
              {showResultsAnalysisPopup.action ===
              questionResultsAnalysisPopupActions.AI_ANALYSIS ? (
                <div className={styles.textAreaTitle}>Generated summary</div>
              ) : null}
              <div className={styles.textAreaContainer}>
                <InsightsTextarea
                  internalData={internalData}
                  setInternalData={setInternalData}
                />
              </div>
              {showResultsAnalysisPopup &&
              showResultsAnalysisPopup.categories ? (
                <GeneratedCategories
                  removedResults={removedResults}
                  setRemovedResults={setRemovedResults}
                  populatedCategories={populatedCategories}
                />
              ) : null}
            </div>
          )}
        </div>
        <div
          className={`${styles.footer} ${
            error && error.length ? styles.footerErrorBackground : ''
          }`}
        >
          {error && error.length ? (
            <>
              <div className={styles.footerErrorBackground} />
              <div className={styles.error}>{error}</div>
            </>
          ) : null}
          {pendingRequest && pendingRequest.pending ? (
            <div>
              {pendingRequest.message || 'Waiting for request to complete...'}
            </div>
          ) : (
            <div />
          )}
          <div className={styles.actions}>
            {!isLoading ? (
              <img
                className={
                  isValidToComplete()
                    ? styles.confirm
                    : `${styles.confirm} ${styles.disabledConfirm}`
                }
                src={tickIcon}
                alt="Confirm icon"
                onClick={() => {
                  if (isValidToComplete()) {
                    onConfirmClick();
                  }
                }}
                role="presentation"
              />
            ) : null}
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
            <img
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={closePopup}
              role="presentation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsAnalysisPopup;
