import React, { useState } from 'react';

import amWarning from '../../../../assets/img/accountmanagement/am-warning.svg';

import tickIcon from '../../../../assets/img/tick.svg';
import closeIcon from '../../../../assets/img/delete.svg';

import styles from './AccountManagementAddEditPopup.module.css';

import BasicSettings from './components/BasicSettings/BasicSettings';
import Loader from '../../../../campaigns/components/Loader/Loader';
import ManagerSelectInvite from './components/ManagerSelectInvite/ManagerSelectInvite';
import BusinessSettings from './components/BusinessSettings/BusinessSettings';
import Permissions from './components/Permissions/Permissions';

const ObjectID = require('bson-objectid');

export default ({
  level,
  parent = null,
  invitationParent = null,
  activePopup,
  title,
  subtitle1,
  subtitle2,
  label1,
  inviteText,
  businessSettingsTitles,
  onSubmit,
  onClose,
  submitLoading,
  invitationType,
  refetchList,
  setActivePopup,
  higherRecords,
  validate = () => true,
  defaultConstants
}) => {
  const defaultPermissions = {
    questionsPerSurveyIncludedInLicence:
      defaultConstants.accountManagementDefaultPermissionValues
        .NUMBER_OF_QUESTIONS_INCLUDED_IN_LICENCE,
    sampleSizeIncludedInLicence:
      defaultConstants.accountManagementDefaultPermissionValues
        .SAMPLE_SIZE_INCLUDED_IN_LICENCE,
    incidenceRateIncludedInLicence:
      defaultConstants.accountManagementDefaultPermissionValues
        .INCIDENCE_RATE_INCLUDED_IN_LICENCE,
    surveysIncludedInLicense:
      defaultConstants.accountManagementDefaultPermissionValues
        .NUMBER_OF_QUESTIONS_INCLUDED_IN_LICENCE,
    allowedToCreateInsights:
      defaultConstants.accountManagementDefaultPermissionValues
        .ALLOWED_TO_CREATE_INSIGHTS,
    allowedToViewInsights:
      defaultConstants.accountManagementDefaultPermissionValues
        .ALLOWED_TO_VIEW_INSIGHTS
  };
  const [datePickerPopupVisible, setDatePickerPopupVisible] = useState(false);
  const [containerTopScroll, setContainerTopScroll] = useState(0);

  const [internalRecord, setInternalRecord] = useState(
    activePopup && activePopup.record
      ? { ...activePopup.record, level, parent }
      : {
          level,
          parent,
          id: ObjectID().toString(),
          permissions: (() => {
            if (level === 1) {
              return defaultPermissions;
            }

            const higherRecord =
              higherRecords && higherRecords.length && higherRecords[0];

            if (
              higherRecord &&
              higherRecord.permissions &&
              (higherRecord.permissions.allowedToCreateInsights === true ||
                higherRecord.permissions.allowedToCreateInsights === false)
            ) {
              return {
                ...defaultPermissions,
                allowedToCreateInsights:
                  higherRecord.permissions.allowedToCreateInsights,
                allowedToViewInsights:
                  higherRecord.permissions.allowedToViewInsights
              };
            }

            return {
              ...defaultPermissions
            };
          })(),
          licence: false,
          businessSettings: []
        }
  );
  const [error, setError] = useState(null);

  const isValid = validate(internalRecord);

  const parentRecordInformation =
    higherRecords && higherRecords.find(r => r.id === parent);
  const parentWithLicence =
    parentRecordInformation && parentRecordInformation.licenceEndDate;

  const listOptionsDivScroll = async event => {
    const elem = event.currentTarget;
    setContainerTopScroll(-1 * elem.scrollTop);
  };

  const setInternalRecordAction = r => {
    setInternalRecord(r);
    setError(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.contentContainer} onScroll={listOptionsDivScroll}>
        <BasicSettings
          level={level}
          subtitle1={subtitle1}
          subtitle2={subtitle2}
          label1={label1}
          inviteText={inviteText}
          internalRecord={internalRecord}
          setInternalRecord={r => setInternalRecordAction(r)}
          activePopup={activePopup}
          invitationParent={invitationParent}
          invitationType={invitationType}
          parentWithLicence={parentWithLicence}
          defaultConstants={defaultConstants}
          datePickerPopupVisible={datePickerPopupVisible}
          setDatePickerPopupVisible={setDatePickerPopupVisible}
          containerTopScroll={containerTopScroll}
        />
        {level === 2 ? (
          <BusinessSettings
            level={level}
            subtitle1={subtitle1}
            subtitle2={subtitle2}
            label1={label1}
            inviteText={inviteText}
            businessSettingsTitles={businessSettingsTitles}
            internalRecord={internalRecord}
            setInternalRecord={r => setInternalRecordAction(r)}
            activePopup={activePopup}
            invitationParent={invitationParent}
            invitationType={invitationType}
            parentWithLicence={parentWithLicence}
            defaultConstants={defaultConstants}
          />
        ) : null}
        {level === 1 || level === 2 ? (
          <Permissions
            internalRecord={internalRecord}
            setInternalRecord={r => setInternalRecordAction(r)}
            isFirstGroup={internalRecord && !internalRecord.licence}
          />
        ) : null}
        <ManagerSelectInvite
          level={level}
          subtitle1={subtitle1}
          subtitle2={subtitle2}
          label1={label1}
          inviteText={inviteText}
          internalRecord={internalRecord}
          setInternalRecord={r => setInternalRecordAction(r)}
          activePopup={activePopup}
          invitationParent={invitationParent}
          invitationType={invitationType}
          parentWithLicence={parentWithLicence}
          defaultConstants={defaultConstants}
        />
      </div>
      {error ? (
        <div className={styles.errorContainer}>
          <img
            className={styles.warningErrorIcon}
            alt="Touchpoints tooltip"
            src={amWarning}
          />
          {error}
        </div>
      ) : null}
      <div className={styles.footer}>
        <div />
        <div className={styles.actions}>
          {submitLoading && (
            <div className={styles.loaderContainer}>
              <Loader size="small" />
            </div>
          )}
          {!submitLoading && [
            isValid ? (
              <img
                key="submit-button"
                className={`${
                  styles.confirm
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
                onClick={async () => {
                  const processedInternalRecord = { ...internalRecord };
                  if (
                    processedInternalRecord &&
                    processedInternalRecord.managers
                  ) {
                    processedInternalRecord.managers = processedInternalRecord.managers.map(
                      m => m.id
                    );
                  }

                  if (processedInternalRecord.permissions) {
                    delete processedInternalRecord.permissions.__typename;
                  }

                  if (
                    processedInternalRecord.businessSettings &&
                    processedInternalRecord.businessSettings.length
                  ) {
                    processedInternalRecord.businessSettings = processedInternalRecord.businessSettings.map(
                      settings => {
                        const businessRecords = settings.records || [];
                        const otherOptionSelected = businessRecords.some(
                          r => r.id === 'Other'
                        );

                        return {
                          ...settings,
                          records: businessRecords.reduce(
                            (filteredRecords, r) => {
                              if (r.id === 'Other') {
                                return filteredRecords;
                              }
                              return [...filteredRecords, r.id];
                            },
                            []
                          ),
                          recordOtherOptionSelected: otherOptionSelected
                        };
                      }
                    );
                  }

                  const res = await onSubmit({
                    variables: processedInternalRecord
                  });

                  const actionPath =
                    activePopup && activePopup.record
                      ? 'updateAccountManagementRecord'
                      : 'createAccountManagementRecord';

                  if (
                    res &&
                    res.data &&
                    res.data[actionPath] &&
                    res.data[actionPath].done
                  ) {
                    setActivePopup(null);
                    await refetchList();
                  }

                  if (
                    res &&
                    res.data &&
                    res.data[actionPath] &&
                    !res.data[actionPath].done
                  ) {
                    if (
                      res.data[actionPath].errors &&
                      res.data[actionPath].errors.length
                    ) {
                      setError(res.data[actionPath].errors[0].message);
                    }
                  }
                }}
                role="presentation"
              />
            ) : (
              <img
                key="submit-button"
                className={`${styles.confirm} ${
                  styles.invalid
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
              />
            ),
            <img
              key="close-button"
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => onClose()}
              role="presentation"
            />
          ]}
        </div>
      </div>
    </div>
  );
};
