import React, { useEffect, useState } from 'react';

import YesNoRadio from '../YesNoRadio/YesNoRadio';

import styles from './Permissions.module.css';

export default ({ internalRecord, setInternalRecord, isFirstGroup }) => {
  const [isCreateDisabled, setIsCreateDisabled] = useState(false);

  useEffect(() => {
    if (internalRecord && internalRecord.permissions) {
      if (internalRecord.permissions.allowedToViewInsights === false) {
        setIsCreateDisabled(true);
      }
    }
  }, []);

  const onRadioClick = (permission, value) => {
    const internalPermissions = internalRecord.permissions;

    if (permission === 'allowedToCreateInsights' && isCreateDisabled) {
      return;
    }

    const newInternalData = { ...internalPermissions };

    newInternalData[permission] = value;

    if (permission === 'allowedToViewInsights' && value === false) {
      setIsCreateDisabled(true);
      newInternalData.allowedToCreateInsights = false;
    } else if (permission === 'allowedToViewInsights' && value === true) {
      setIsCreateDisabled(false);
    }

    setInternalRecord({
      ...internalRecord,
      permissions: newInternalData
    });
  };

  const permissions = [
    {
      label: 'Create insights',
      value: internalRecord.permissions.allowedToCreateInsights,
      onChange: newAllocatedValue =>
        onRadioClick('allowedToCreateInsights', newAllocatedValue),
      disabled: isCreateDisabled
    },
    {
      label: 'View insights',
      value: internalRecord.permissions.allowedToViewInsights,
      onChange: newAllocatedValue =>
        onRadioClick('allowedToViewInsights', newAllocatedValue),
      disabled: false
    }
  ];

  return (
    <div className={styles.content}>
      <div
        className={
          isFirstGroup
            ? `${styles.subtitle} ${styles.firstGroup}`
            : styles.subtitle
        }
      >
        Permissions
      </div>
      {permissions.map(permission => (
        <YesNoRadio
          key={permission.label}
          label={permission.label}
          value={permission.value}
          onChange={permission.onChange}
          disabled={permission.disabled}
        />
      ))}
      <div className={styles.bottomBorderDiv} />
    </div>
  );
};
