import React, { useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';

import { useQuery, useMutation } from '@apollo/react-hooks';

import isActionDropdownAllowed from '../../helpers/isActionDropdownAllowed';
import isAddingAllowed from '../../helpers/isAddingAllowed';

import AppBar from '../../../../base/components/AppBar/AppBar';
import Popup from '../../../../campaigns/components/Popup';
import AccountManagementHeader from '../AccountManagementHeader/AccountManagementHeader';
import AccountManagementRecord from '../AccountManagementRecord/AccountManagementRecord';
import AccountManagementAddEditPopup from '../AccountManagementAddEditPopup/AccountManagementAddEditPopup';
import AccountManagementRemovePopup from '../AccountManagementRemovePopup/AccountManagementRemovePopup';
import AccountManagementExportPopup from '../AccountManagementExportPopup/AccountManagementExportPopup';
import AccountManagementRecordLoader from '../AccountManagementRecordLoader/AccountManagementRecordLoader';
import SortingArrow from './components/SortingArrow';

import questionMark from '../../../../assets/img/accountmanagement/am-questionmark.svg';

import {
  businessConfigurationTypes,
  recordActionPopups
} from '../../helpers/constants';
import getTitle from '../../helpers/getTitle';

import styles from './AccountManagementRecordsPage.module.css';

import {
  GET_ACCOUNT_MANAGEMENT_RECORDS,
  CREATE_ACCOUNT_MANAGEMENT_RECORD,
  UPDATE_ACCOUNT_MANAGEMENT_RECORD,
  REMOVE_ACCOUNT_MANAGEMENT_RECORD
} from '../../../../graphql/AccountManagement';

export default props => {
  const {
    history,
    level,
    parent = null,
    recordsPerPage,
    title = null,
    onAddButtonLabel,
    addPopupTitle,
    addPopupSubtitle1,
    addPopupSubtitle2,
    editPopupTitle,
    removePopupTitle,
    businessSettingsTitles,
    inviteText,
    goToRecordRoute,
    invitationType,
    isAdmin,
    accountManagement,
    defaultConstants,
    setSelectedAccountManagementRecord
  } = props;

  const sortingOrder = {
    ASC: 'asc',
    DESC: 'desc'
  };
  const sortingOptions = {
    startDate: 'startDate',
    endDate: 'endDate',
    touchpoints: 'touchpoints'
  };

  const [activePopup, setActivePopup] = useState(null);
  const [filtering, setFiltering] = useState({
    page: 0,
    search: ''
  });
  const [sorting, setSorting] = useState(null);

  const updateSorting = sortingOption => {
    if (sorting && sorting[sortingOption]) {
      setSorting({
        [sortingOption]:
          sorting[sortingOption] === sortingOrder.DESC
            ? sortingOrder.ASC
            : sortingOrder.DESC
      });
    } else {
      setSorting({
        [sortingOption]: sortingOrder.DESC
      });
    }
  };

  const recordVariables = {
    parent,
    start: filtering.page * recordsPerPage,
    end: (filtering.page + 1) * recordsPerPage,
    search: filtering.search,
    ...(sorting ? { sorting } : {})
  };

  const permissionFields = isAdmin
    ? `
    permissions {
      questionsPerSurveyIncludedInLicence
      sampleSizeIncludedInLicence
      incidenceRateIncludedInLicence
      surveysIncludedInLicense
      allowedToCreateInsights
      allowedToViewInsights
    }
  `
    : '';

  const {
    data: {
      getAccountManagementRecords: { records, totalCount, higherRecords } = {}
    } = {},
    refetch,
    loading: getAccountsLoading
  } = useQuery(GET_ACCOUNT_MANAGEMENT_RECORDS(permissionFields), {
    variables: recordVariables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const [
    createAccountManagementRecord,
    { loading: createLoading }
  ] = useMutation(CREATE_ACCOUNT_MANAGEMENT_RECORD);
  const [
    updateAccountManagementRecord,
    { loading: updateLoading }
  ] = useMutation(UPDATE_ACCOUNT_MANAGEMENT_RECORD);
  const [
    removeAccountManagementRecord,
    { loading: removeLoading }
  ] = useMutation(REMOVE_ACCOUNT_MANAGEMENT_RECORD);

  const submitLoading = createLoading || updateLoading || removeLoading;

  const memoizedTitle = useMemo(() => getTitle(level, title, higherRecords), [
    higherRecords
  ]);

  const memoizedRouteSubtitle = useMemo(
    () => goToRecordRoute(history, higherRecords, styles),
    [higherRecords]
  );

  const actionsAllowed = useMemo(
    () => isActionDropdownAllowed(level, isAdmin, accountManagement, parent),
    [level, parent]
  );

  const addingAllowed = useMemo(
    () => isAddingAllowed(level, isAdmin, accountManagement, parent),
    [level, parent]
  );

  const refetchList = async () => {
    await refetch(recordVariables);
  };

  // Only level 1 records show touchpoints value
  const someRecordHasTouchpointsValue =
    level === 1 &&
    records &&
    records.length &&
    records.some(r => r.creditsAllocated !== null);

  const validateBusinessSetting = (settings, type) => {
    if (
      settings.type === type &&
      settings.records &&
      settings.records.length &&
      (!settings.records.some(r => r.id === 'Other') ||
        (settings.records.some(r => r.id === 'Other') &&
          settings.recordCustomName &&
          settings.recordCustomName.length)) &&
      (settings.type === businessConfigurationTypes.GOAL ||
        (settings.type === businessConfigurationTypes.CHALLENGE &&
          settings.description &&
          settings.description.length))
    ) {
      return true;
    }
    return false;
  };

  const validateAddEditPopupSave = internalRecord => {
    if (
      internalRecord &&
      internalRecord.name &&
      internalRecord.name.length &&
      (internalRecord.licence === false ||
        (internalRecord.licence === true &&
          internalRecord.licenceStartDate &&
          internalRecord.licenceEndDate))
    ) {
      if (level === 2) {
        const level2HasBusinessSettings =
          internalRecord.businessSettings &&
          internalRecord.businessSettings.length &&
          internalRecord.businessSettings.some(settings =>
            validateBusinessSetting(settings, businessConfigurationTypes.GOAL)
          ) &&
          internalRecord.businessSettings.some(settings =>
            validateBusinessSetting(
              settings,
              businessConfigurationTypes.CHALLENGE
            )
          );

        return !!level2HasBusinessSettings;
      }
      return true;
    }
    return false;
  };

  return (
    <>
      <AppBar
        {...props}
        appBarProperties={{
          routeSubtitle: memoizedRouteSubtitle
        }}
      />
      <div className={styles.container}>
        <AccountManagementHeader
          title={memoizedTitle}
          onAddButtonLabel={onAddButtonLabel}
          addingAllowed={addingAllowed}
          onAddClick={() => setActivePopup({ type: recordActionPopups.ADD })}
          search={filtering.search}
          onSearch={searchText =>
            setFiltering({ ...filtering, search: searchText.trim() })
          }
          isAdminAndLevelOne={isAdmin && level === 1}
          onGlobalExportClick={recordActionPopupType =>
            setActivePopup({ type: recordActionPopupType })
          }
        />
        {getAccountsLoading ? <AccountManagementRecordLoader /> : null}
        {!getAccountsLoading ? (
          <table
            border="0"
            cellSpacing="0"
            cellPadding="0"
            className={styles.mainTable}
          >
            <thead>
              <tr>
                <th className={styles.headerRow}>Name</th>
                <th
                  className={`${styles.headerRow} ${
                    styles.touchpointsContainer
                  } ${
                    sorting && sorting[sortingOptions.touchpoints]
                      ? styles.activeSorting
                      : ''
                  }`}
                >
                  {someRecordHasTouchpointsValue ? (
                    <>
                      <div className={styles.touchpointsTooltipContainer}>
                        <img
                          className={styles.touchpointsTooltipIcon}
                          alt="Touchpoints tooltip"
                          src={questionMark}
                        />
                        <div className={styles.touchpointsTooltipPopup}>
                          <div className={styles.topTooltipPopupText}>
                            # surveys published
                          </div>
                          <div>
                            surveys in fair use based on time passed in license
                          </div>
                        </div>
                      </div>
                      Touchpoints
                    </>
                  ) : null}
                </th>
                {level === 1 ? (
                  <>
                    <th
                      className={`${styles.headerRow} ${
                        styles.licenceStartContainer
                      } ${
                        sorting && sorting[sortingOptions.startDate]
                          ? styles.activeSorting
                          : ''
                      } ${styles.clickable}`}
                      onClick={() => updateSorting(sortingOptions.startDate)}
                    >
                      License start date
                      <SortingArrow
                        sorting={sorting}
                        sortingOrder={sortingOrder}
                        sortingOption={sortingOptions.startDate}
                      />
                    </th>
                    <th
                      className={`${styles.headerRow} ${
                        styles.licenceEndContainer
                      } ${
                        sorting && sorting[sortingOptions.endDate]
                          ? styles.activeSorting
                          : ''
                      } ${styles.clickable}`}
                      onClick={() => updateSorting(sortingOptions.endDate)}
                    >
                      License end date
                      <SortingArrow
                        sorting={sorting}
                        sortingOrder={sortingOrder}
                        sortingOption={sortingOptions.endDate}
                      />
                    </th>
                  </>
                ) : null}
                <th className={styles.headerRow} />
              </tr>
            </thead>
            <tbody>
              {records && records.length
                ? records.map((cT, i) => (
                    <AccountManagementRecord
                      key={`record-item-${i.toString()}`}
                      level={level}
                      parent={parent}
                      record={cT}
                      setActivePopup={setActivePopup}
                      onRecordClick={record => {
                        const path = history.location.pathname;
                        history.push(`${path}/${record.id}`);
                      }}
                      actionsAllowed={actionsAllowed}
                      isAdmin={isAdmin}
                      accountManagement={accountManagement}
                      higherRecords={higherRecords}
                      onSelectRecord={() => {
                        if (cT && cT.id) {
                          setSelectedAccountManagementRecord(cT);
                          // Save selected value in local storage
                          localStorage.setItem('qd_am_selected_record', cT.id);
                        }
                      }}
                      onGlobalExportClick={(recordActionPopupType, record) =>
                        setActivePopup({
                          type: recordActionPopupType,
                          id: record.id,
                          ...(record.licenceStartDate && {
                            licenceStartDate: new Date(record.licenceStartDate)
                          }),
                          ...(record.licenceEndDate && {
                            licenceEndDate: new Date(record.licenceEndDate)
                          })
                        })
                      }
                    />
                  ))
                : null}
              {!records || !records.length ? (
                <tr className={styles.noResultsPlaceholder}>
                  <td colSpan="5">No results found</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        ) : null}
        {activePopup && activePopup.type === recordActionPopups.ADD ? (
          <Popup
            component={
              <AccountManagementAddEditPopup
                level={level}
                parent={parent}
                title={addPopupTitle}
                subtitle1={addPopupSubtitle1}
                subtitle2={addPopupSubtitle2}
                businessSettingsTitles={businessSettingsTitles}
                inviteText={inviteText}
                onSubmit={createAccountManagementRecord}
                onClose={() => setActivePopup(null)}
                submitLoading={submitLoading}
                invitationType={invitationType}
                setActivePopup={setActivePopup}
                refetchList={refetchList}
                higherRecords={higherRecords}
                isAdmin={isAdmin}
                validate={internalRecord =>
                  validateAddEditPopupSave(internalRecord)
                }
                label1={level === 1 ? 'Credits purchased' : 'Credits allocated'}
                defaultConstants={defaultConstants}
              />
            }
            customStyles={{ padding: 'none' }}
            onClose={() => {}}
          />
        ) : null}
        {activePopup && activePopup.type === recordActionPopups.EDIT ? (
          <Popup
            component={
              <AccountManagementAddEditPopup
                activePopup={activePopup}
                parent={parent}
                level={level}
                title={editPopupTitle}
                subtitle1={addPopupSubtitle1}
                subtitle2={addPopupSubtitle2}
                businessSettingsTitles={businessSettingsTitles}
                inviteText={inviteText}
                onSubmit={updateAccountManagementRecord}
                onClose={() => setActivePopup(null)}
                setActivePopup={setActivePopup}
                refetchList={refetchList}
                submitLoading={submitLoading}
                invitationType={invitationType}
                higherRecords={higherRecords}
                isAdmin={isAdmin}
                validate={internalRecord =>
                  validateAddEditPopupSave(internalRecord)
                }
                label1={level === 1 ? 'Credits purchased' : 'Credits allocated'}
                defaultConstants={defaultConstants}
              />
            }
            customStyles={{ padding: 'none' }}
            onClose={() => {}}
          />
        ) : null}
        {activePopup && activePopup.type === recordActionPopups.DELETE ? (
          <Popup
            component={
              <AccountManagementRemovePopup
                activePopup={activePopup}
                title={removePopupTitle}
                subtitle1={`Are you sure you want to delete ${activePopup &&
                  activePopup.record &&
                  activePopup.record.name}?`}
                onSubmit={async recordId => {
                  await removeAccountManagementRecord({
                    variables: { id: recordId }
                  });
                  setActivePopup(null);
                  await refetch(recordVariables);
                }}
                onClose={() => setActivePopup(null)}
              />
            }
            customStyles={{ padding: 'none' }}
            onClose={() => {}}
          />
        ) : null}
        {activePopup &&
        activePopup.type === recordActionPopups.GLOBAL_SCOPING_SURVEYS ? (
          <Popup
            component={
              <AccountManagementExportPopup
                title="Scopings"
                filename="export_scopings"
                recordActionPopupType={activePopup.type}
                onClose={() => setActivePopup(null)}
              />
            }
            customStyles={{ padding: 'none' }}
            onClose={() => {}}
            showOverflow
          />
        ) : null}
        {activePopup &&
        activePopup.type === recordActionPopups.GLOBAL_BUDGET_OVERVIEW ? (
          <Popup
            component={
              <AccountManagementExportPopup
                title="Fair use overview"
                filename="export_global_budget_overview"
                recordActionPopupType={activePopup.type}
                onClose={() => setActivePopup(null)}
              />
            }
            customStyles={{ padding: 'none' }}
            onClose={() => {}}
            showOverflow
          />
        ) : null}
        {activePopup &&
        activePopup.type === recordActionPopups.LICENSE_OVERVIEW ? (
          <Popup
            component={
              <AccountManagementExportPopup
                title="License overview"
                filename="export_license_budget_overview"
                recordActionPopupType={activePopup.type}
                recordId={activePopup.id}
                licenceStartDate={activePopup.licenceStartDate || null}
                licenceEndDate={activePopup.licenceEndDate || null}
                onClose={() => setActivePopup(null)}
              />
            }
            customStyles={{ padding: 'none' }}
            onClose={() => {}}
            showOverflow
          />
        ) : null}
        {recordsPerPage && records && records.length ? (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={Math.ceil(totalCount / recordsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            initialPage={filtering.page}
            forcePage={filtering.page}
            disableInitialCallback
            onPageChange={pageNumber =>
              setFiltering({ ...filtering, page: pageNumber.selected })
            }
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        ) : null}
      </div>
    </>
  );
};
