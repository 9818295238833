import React from 'react';

import closeIcon from '../../../../../../../../../../../../assets/img/delete.svg';

import styles from '../GeneratedCategories.module.css';

const ResultUnderCategory = props => {
  const { result, isRemoved, onResultRemoveToggle } = props;

  return (
    <div className={styles.resultUnderCategory}>
      {isRemoved ? <span className={styles.marker} /> : null}
      <span
        className={`${styles.resultUnderCategoryText} ${
          isRemoved ? styles.deselected : ''
        }`}
        title={result.answer_string}
      >
        {result.answer_string}
      </span>
      <div className={styles.removeIconContainer}>
        <img
          className={styles.removeIconResult}
          src={closeIcon}
          alt="Remove result"
          role="presentation"
          onClick={() =>
            onResultRemoveToggle(result.resultId, result.parentId, isRemoved)
          }
        />
      </div>
    </div>
  );
};

export default ResultUnderCategory;
