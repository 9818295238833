import React, { useState, useMemo, useEffect } from 'react';

import Icon from '../../../Icon';
import Slider from './Questions/Slider/Slider';
import Camera from './Questions/Camera';
import AnswersDistribution from './Questions/AnswersDistribution/AnswersDistribution';
import TimeTaken from './Questions/TimeTaken';
import QuestionThumbnail from '../../QuestionThumbnail/QuestionThumbnail';
import Videos from './Questions/Videos';
import OpenQuestion from '../Questions/OpenQuestion/OpenQuestion';
import AiAnswersAnalysis from '../Questions/OpenQuestion/ResultsAnalysis/components/AiAnswersAnalysis/AiAnswersAnalysis';
import AnswerProbablityBars from './AnswerProbablityBars/AnswerProbablityBars';
import WordCloudsContainer from './WordCloudsContainer/WordCloudsContainer';
import Draft from './Draft/Draft';
import Matrix from './Questions/Matrix/Matrix';
import MultipleChoiceToolbar from './Toolbars/MultipleChoiceToolbar';
import RatingScaleToolbar from './Toolbars/RatingScaleToolbar';
import OpenAnswerToolbar from './Toolbars/OpenAnswerToolbar';
import MergedGraphToolbar from '../Graphs/components/Toolbars/MergedGraphToolbar';
import RespondentsLegend from './RespondentsLegend/RespondentsLegend';

import { CHART_VIEW_NAMES } from '../../../../helpers/constants';

const views = {
  ANSWERS: 'ANSWERS',
  TIME_TAKEN: 'TIME_TAKEN',
  PROBABILITY_ANSWER_SELECTED: 'PROBABILITY_ANSWER_SELECTED',
  WORD_CLOUD: 'WORD_CLOUD'
};

const mcAnswerSortingOrders = {
  DEFAULT: 'Sort by default',
  ASC: 'Sort by value low - high',
  DESC: 'Sort by value high - low',
  ANSWER: 'Sort by answer option'
};

/* DO NOT CHANGE THE KEYS OF THESE LABELS - THEY ARE SAVED WITH INSIGHTS */
const chartViewLabels = {
  [CHART_VIEW_NAMES.DEFAULT]: 'Bar graph (vertical)',
  [CHART_VIEW_NAMES.HORIZONTAL]: 'Bar graph (horizontal)',
  [CHART_VIEW_NAMES.STACKED]: 'Stacked bar'
};
const mcChartViewLabels = {
  [CHART_VIEW_NAMES.DEFAULT]: 'Default',
  [CHART_VIEW_NAMES.STACKED]: 'Stacked bar'
};

const Question = props => {
  const {
    campaign,
    index,
    block,
    onTimePrettyPrint,
    onToggleFilter,
    headerStats,
    setVideoPopup,
    activeFilters,
    resultBlocks,
    onAssignCategoresToOpenAnswer,
    isAdmin,
    isAllowedToExecuteRequests,
    onToggleSorting,
    activeSorting,
    initialWordClouds,
    hiddenWordCloudWords,
    viewToken,
    setShowAddInsightsPopup,
    onToggleActiveBooleanState,
    activeFilteringOnSelection,
    chartView = CHART_VIEW_NAMES.DEFAULT,
    setChartView,
    insightQuestionType,
    activeInsightId,
    allowedToCreateInsights
  } = props;

  if (block.type === 'Distributor') return null;

  let filteringOnSelection =
    ['Multiple Choice Question', 'Slider', 'Open Question', 'Matrix'].indexOf(
      block.type
    ) > -1;

  if (activeFilteringOnSelection) {
    filteringOnSelection = activeFilteringOnSelection.active;
  }

  const setFilteringOnSelection = newFilteringOnSelection => {
    onToggleActiveBooleanState(
      'activeFilteringOnSelection',
      block.id,
      newFilteringOnSelection
    );
  };

  const initialView = () => {
    switch (block.type) {
      case 'Iframe':
        return views.TIME_TAKEN;

      case 'Video':
        return views.TIME_TAKEN;

      case 'Message':
        return views.TIME_TAKEN;

      case 'Open Question':
        return views.ANSWERS;

      default:
        return views.ANSWERS;
    }
  };

  const [view, setView] = useState(initialView());
  const [questionChartParent, setQuestionChartParent] = useState(null);
  const [expandOpenQuestion, setExpandOpenQuestion] = useState(false);
  const [answerSearch, setAnswerSearch] = useState('');
  const [
    selectAllOpenQuestionCategories,
    setSelectAllOpenQuestionCategories
  ] = useState(false);
  const [openAnswerCategoryPairs, setOpenAnswerCategoryPairs] = useState([]);
  const [probabilityAnswerSelected, setProbabilityAnswerSelected] = useState(
    false
  );
  const [isPercentage, setIsPercentage] = useState(true);
  const [showStackedAverageScores, setShowStackedAverageScores] = useState(
    false
  );

  const initialOpenQuestionCategories = useMemo(
    () => {
      if (
        block.type === 'Open Question' &&
        block.unfilteredResults &&
        block.unfilteredResults.length
      ) {
        const existingCategories = [];
        const resultsCategoryPairs = [];
        block.unfilteredResults.forEach(result => {
          if (result.categories && result.categories.length) {
            result.categories.forEach(categoryToAdd => {
              if (existingCategories.indexOf(categoryToAdd) === -1) {
                existingCategories.push(categoryToAdd);
                resultsCategoryPairs.push({
                  category: categoryToAdd,
                  string: result.answer_string
                });
              } else {
                const foundIndex = resultsCategoryPairs.findIndex(
                  pair => pair.category === categoryToAdd
                );
                resultsCategoryPairs[foundIndex] = {
                  ...resultsCategoryPairs[foundIndex],
                  string: (resultsCategoryPairs[foundIndex].string += `${' '}${
                    result.answer_string
                  }`)
                };
              }
            });
          }
        });
        setOpenAnswerCategoryPairs(resultsCategoryPairs);
        return existingCategories;
      }
      return [];
    },
    [block.unfilteredResults]
  );

  useEffect(
    () => {
      setView(initialView());
    },
    [activeInsightId]
  );

  const perepareValuesForSlider = (values, prepareUnfilteredData) => {
    if (values && values.length) {
      let calculatedValues = values
        .map(v => ({
          ...v,
          x: v.x === null ? block.minValue - 1 : v.x,
          xLabel: block.allowSkip && v.x === null ? 'N/A' : null
        }))
        .sort((a, b) => a.x - b.x);

      if (filteringOnSelection && prepareUnfilteredData) {
        const filteredData = perepareValuesForSlider(block.resultStats.values);
        /* eslint-disable no-param-reassign */
        calculatedValues = calculatedValues.reduce(
          (unfilteredValuesWithoutSelection, currentDataPoint) => {
            if (filteredData && filteredData.length) {
              const matchingValue = filteredData.find(
                datapoint => datapoint.x === currentDataPoint.x
              );
              if (matchingValue) {
                currentDataPoint.y -= matchingValue.y;
              }
              unfilteredValuesWithoutSelection.push(currentDataPoint);
            }
            return unfilteredValuesWithoutSelection;
          },
          []
        );
        /* eslint-enable no-param-reassign */
      }
      return calculatedValues;
    }

    return [];
  };

  const displayBlockQuestion = blockQuestion => {
    let question;
    try {
      JSON.parse(blockQuestion)
        .blocks.map(draftBlock => draftBlock.text)
        .join(' ');
    } catch (error) {
      question = blockQuestion;
      return question;
    }
    question = <Draft block={block} />;
    return question;
  };

  if (!isAdmin && block.only_visible_for_admins) {
    return null;
  }

  const getMatchingOpenQuestionAnswers = selectedWordsToSearch =>
    block.results
      .filter(a =>
        selectedWordsToSearch.some(
          searchValue =>
            a.answer_string.toLowerCase().search(`\\b${searchValue}\\b`) >= 0
        )
      )
      .map(answer => ({
        blockId: answer.block,
        resultId: answer.resultId,
        categories: answer.categories ? answer.categories : []
      }));

  const assignCategoriesFromWords = async (words, selectedCategories) => {
    if (isAllowedToExecuteRequests) {
      const categorization = getMatchingOpenQuestionAnswers(words).map(
        answer => {
          const allCategories = [...answer.categories, ...selectedCategories];
          const newCategories = allCategories.filter(
            (item, itemIndex) => allCategories.indexOf(item) === itemIndex
          );

          return {
            resultId: answer.resultId,
            blockId: answer.blockId,
            categories: newCategories
          };
        }
      );
      onAssignCategoresToOpenAnswer(campaign.id, categorization, viewToken);
    }
  };

  let icon = block.name;

  if (block.name === 'checkboxes') icon = 'multiple-choice';
  else if (block.show_selected_answers_order) icon = 'ranking';
  else if (block.isCommunityAssignQuestion) icon = 'addToCommunity';

  const getQuestionTitle = () => {
    if (block.type === 'Iframe') {
      if (block.source && block.source !== '') return block.source;
      return 'Showcase a website';
    }
    if (block.type === 'Video') {
      return 'Video';
    }
    if (
      block.type === 'Matrix' &&
      block.matrix &&
      block.matrix.xQuestion &&
      block.matrix.yQuestion
    ) {
      if (chartView === CHART_VIEW_NAMES.STACKED) {
        return (
          <div>
            {block.matrix.decisionFrameworkType !== 'SINGLE' ? (
              <div className="block-text-matrix-axis">
                X-axis:{' '}
                <span className="block-text-matrix-axis-bold">
                  {block.matrix.xLabel ? block.matrix.xLabel : ''}
                </span>
              </div>
            ) : null}
            <Draft block={{ ...block, question: block.matrix.xQuestion }} />
          </div>
        );
      }
      return (
        <div>
          <Draft block={{ ...block, question: block.matrix.xQuestion }} />
          {block.matrix.decisionFrameworkType !== 'SINGLE' && (
            <div style={{ marginTop: '20px' }}>
              <Draft block={{ ...block, question: block.matrix.yQuestion }} />
            </div>
          )}
        </div>
      );
    }
    if (block.question) {
      return displayBlockQuestion(block.question);
    }
    if (block.show_selected_answers_order) {
      return 'Ranking Options Question';
    }
    return block.type;
  };

  const showCompareToTotalSample =
    activeFilters &&
    activeFilters.length &&
    !activeFilters.some(activeFilter => activeFilter.filterName === block.id);

  const openAnswerCategoryFiltering =
    activeFilters &&
    activeFilters.length &&
    activeFilters.some(
      activeFilter => activeFilter.filterName === `CAT-${block.id}`
    );

  const extremeUsersFilterLabels = [
    'xLovers',
    'yLovers',
    'xLoversyLovers',
    'xHaters',
    'yHaters',
    'xHatersyHaters'
  ];

  return (
    <div className="results-container" index={index.toString()}>
      <QuestionThumbnail
        block={block}
        path="media_filename"
        label="Upload"
        image="media_filename"
        setVideoPopup={setVideoPopup}
        type={block.name}
      />
      {['Multiple Choice Question', 'Checkboxes'].indexOf(block.type) > -1 && (
        <MultipleChoiceToolbar
          block={block}
          mcAnswerSortingOrders={mcAnswerSortingOrders}
          onToggleSorting={onToggleSorting}
          activeSorting={activeSorting}
          views={views}
          view={view}
          setView={setView}
          initialView={initialView()}
          probabilityAnswerSelected={probabilityAnswerSelected}
          setProbabilityAnswerSelected={setProbabilityAnswerSelected}
          filteringOnSelection={filteringOnSelection}
          setFilteringOnSelection={setFilteringOnSelection}
          showCompareToTotalSample={showCompareToTotalSample}
          setShowAddInsightsPopup={setShowAddInsightsPopup}
          viewToken={viewToken}
          chartView={chartView}
          setChartView={setChartView}
          chartViewLabels={mcChartViewLabels}
          chartViewNames={CHART_VIEW_NAMES}
          isPercentage={isPercentage}
          setIsPercentage={setIsPercentage}
          showStackedAverageScores={showStackedAverageScores}
          setShowStackedAverageScores={setShowStackedAverageScores}
          allowedToCreateInsights={allowedToCreateInsights}
        />
      )}
      {block.type === 'Slider' && (showCompareToTotalSample || !viewToken) ? (
        <RatingScaleToolbar
          filteringOnSelection={filteringOnSelection}
          setFilteringOnSelection={setFilteringOnSelection}
          questionId={block.id}
          setShowAddInsightsPopup={setShowAddInsightsPopup}
          showCompareToTotalSample={showCompareToTotalSample}
          viewToken={viewToken}
          allowedToCreateInsights={allowedToCreateInsights}
        />
      ) : null}
      {block.type === 'Open Question' &&
      ((activeFilters &&
        activeFilters.length &&
        !openAnswerCategoryFiltering) ||
        !viewToken) ? (
        <OpenAnswerToolbar
          filteringOnSelection={filteringOnSelection}
          setFilteringOnSelection={setFilteringOnSelection}
          questionId={block.id}
          setShowAddInsightsPopup={setShowAddInsightsPopup}
          openAnswerCategoryFiltering={openAnswerCategoryFiltering}
          viewToken={viewToken}
          allowedToCreateInsights={allowedToCreateInsights}
        />
      ) : null}
      {block.type === 'Matrix' ? (
        <MergedGraphToolbar
          chartView={chartView}
          setChartView={setChartView}
          chartViewLabels={chartViewLabels}
          chartViewNames={CHART_VIEW_NAMES}
          isPercentage={isPercentage}
          setIsPercentage={setIsPercentage}
          isMatrixQuestion
          filteringOnSelection={filteringOnSelection}
          setFilteringOnSelection={setFilteringOnSelection}
          showCompareToTotalSample={showCompareToTotalSample}
          block={block}
          setShowAddInsightsPopup={setShowAddInsightsPopup}
          viewToken={viewToken}
          showStackedAverageScores={showStackedAverageScores}
          setShowStackedAverageScores={setShowStackedAverageScores}
          insightQuestionType={insightQuestionType}
          survey={campaign}
          allowedToCreateInsights={allowedToCreateInsights}
        />
      ) : null}
      <div className="block-structure">
        <div className="block-number" title={block.globalIndex}>
          {block && block.globalIndex ? block.formattedGlobalIndex : index + 1}
        </div>
        <div className="block-icon">
          <span className="icon">
            <Icon type={icon} />
          </span>
        </div>
        <div className="block-content">
          <div className="block-text">
            <div>{getQuestionTitle()}</div>
            <div className="block-respondents">
              <RespondentsLegend
                filtering={activeFilters.length}
                totalResultsCount={block.unfilteredResults.length}
                selectedResultsCount={block.results.length}
                filteringOnSelection={filteringOnSelection}
                questionHasFilteredData={
                  (['Multiple Choice Question', 'Slider', 'Matrix'].indexOf(
                    block.type
                  ) > -1 &&
                    showCompareToTotalSample) ||
                  (block.type === 'Open Question' &&
                    !openAnswerCategoryFiltering &&
                    initialOpenQuestionCategories &&
                    initialOpenQuestionCategories.length)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="result-view-type-container">
        <div className="results-container-left">
          {block.type === 'Open Question' ? (
            <span
              role="presentation"
              className={
                view === views.WORD_CLOUD
                  ? 'view-type-item active'
                  : 'view-type-item'
              }
              onClick={() => {
                setView(views.WORD_CLOUD);
              }}
            >
              Word cloud
            </span>
          ) : null}
          {initialView() === views.ANSWERS || block.type === 'Open Question' ? (
            <span
              role="presentation"
              className={
                view === views.ANSWERS ||
                view === views.PROBABILITY_ANSWER_SELECTED
                  ? 'view-type-item active'
                  : 'view-type-item'
              }
              onClick={() => {
                setView(views.ANSWERS);
              }}
            >
              Answers
            </span>
          ) : null}
          {campaign.enableTimeTaken && (
            <span
              role="presentation"
              className={
                view === views.TIME_TAKEN
                  ? 'view-type-item active'
                  : 'view-type-item'
              }
              onClick={() => {
                setView(views.TIME_TAKEN);
              }}
            >
              Time taken
            </span>
          )}
        </div>
        <div className="results-container-right">
          {block.type === 'Open Question' &&
          view === views.ANSWERS &&
          !viewToken ? (
            <AiAnswersAnalysis
              surveyId={campaign.id}
              questionId={block.id}
              results={block.results || []}
              resultsAnalysisData={campaign.resultsAnalysisData || {}}
            />
          ) : null}
          {block.type === 'Open Question' && view === views.ANSWERS ? (
            <span
              role="presentation"
              className={`expand-open-question-button ${
                expandOpenQuestion ? 'expand-open-question-button-active' : ''
              }`}
              onClick={() => {
                setExpandOpenQuestion(!expandOpenQuestion);
              }}
            >
              Expand answers
            </span>
          ) : null}
        </div>
      </div>
      {view === views.ANSWERS ? (
        <div>
          <div
            className="answers-container"
            ref={elem => setQuestionChartParent(elem)}
          >
            {block.type === 'Camera' ? (
              <Camera results={block.results} block={block} />
            ) : null}
            {block.type === 'Open Question' ? (
              <OpenQuestion
                campaign={campaign}
                block={block}
                onTimePrettyPrint={onTimePrettyPrint}
                activeFilters={activeFilters}
                onToggleFilter={onToggleFilter}
                resultBlocks={resultBlocks}
                onAssignCategoresToOpenAnswer={onAssignCategoresToOpenAnswer}
                expandOpenQuestion={expandOpenQuestion}
                answerSearch={answerSearch}
                setAnswerSearch={setAnswerSearch}
                selectAllOpenQuestionCategories={
                  selectAllOpenQuestionCategories
                }
                setSelectAllOpenQuestionCategories={
                  setSelectAllOpenQuestionCategories
                }
                categories={initialOpenQuestionCategories}
                isAdmin={isAdmin}
                isAllowedToExecuteRequests={isAllowedToExecuteRequests}
                filteringOnSelectionEnabled={
                  !!(
                    filteringOnSelection &&
                    activeFilters &&
                    activeFilters.length
                  )
                }
                openAnswerCategoryFiltering={openAnswerCategoryFiltering}
                viewToken={viewToken}
              />
            ) : null}
            {block.type === 'Slider' ? (
              <Slider
                parent={questionChartParent}
                data={perepareValuesForSlider(block.resultStats.values)}
                unfilteredData={
                  activeFilters.length
                    ? perepareValuesForSlider(
                        block.unfilteredResultStats.values,
                        true
                      )
                    : null
                }
                start={block.minValue}
                end={block.maxValue}
                onChartClick={onToggleFilter}
                filterCollectDataGroup={{
                  name: block.id,
                  valuePath: 'answer',
                  resultsPath: 'attributes.block_results'
                }}
                block={block}
                activeFilters={activeFilters}
                responses={(headerStats && headerStats.responses) || 0}
                extremeUsersFilterLabels={extremeUsersFilterLabels}
              />
            ) : null}
            {['Multiple Choice Question', 'Checkboxes'].indexOf(block.type) >
            -1 ? (
              <AnswersDistribution
                block={block}
                onToggleFilter={onToggleFilter}
                headerStats={headerStats}
                activeFilters={activeFilters}
                activeSorting={activeSorting}
                campaignId={campaign.id}
                probabilityAnswerSelected={probabilityAnswerSelected}
                filteringOnSelectionEnabled={
                  !!(
                    filteringOnSelection &&
                    activeFilters &&
                    activeFilters.length
                  )
                }
                extremeUsersFilterLabels={extremeUsersFilterLabels}
                isAdmin={isAdmin}
                isAllowedToExecuteRequests={isAllowedToExecuteRequests}
                viewToken={viewToken}
                chartView={chartView}
                chartViewNames={CHART_VIEW_NAMES}
                isPercentage={isPercentage}
                showStackedAverageScores={showStackedAverageScores}
              />
            ) : null}
            {block.type === 'Camera (Video)' ? (
              <Videos results={block.results} block={block} />
            ) : null}
            {block.type === 'Matrix' ? (
              <Matrix
                block={block}
                surveyLanguage={campaign.language}
                activeFilters={activeFilters}
                chartView={chartView}
                chartViewNames={CHART_VIEW_NAMES}
                isPercentage={isPercentage}
                onToggleFilter={onToggleFilter}
                filteringOnSelectionEnabled={
                  !!(
                    filteringOnSelection &&
                    activeFilters &&
                    activeFilters.length
                  )
                }
                responses={(headerStats && headerStats.responses) || 0}
                extremeUsersFilterLabels={extremeUsersFilterLabels}
                filteringOnSelection={filteringOnSelection}
                showStackedAverageScores={showStackedAverageScores}
              />
            ) : null}
          </div>
        </div>
      ) : null}

      {view === views.TIME_TAKEN && campaign && campaign.enableTimeTaken ? (
        <TimeTaken block={block} onTimePrettyPrint={onTimePrettyPrint} />
      ) : null}

      {view === views.PROBABILITY_ANSWER_SELECTED &&
      !block.show_selected_answers_order ? (
        <AnswerProbablityBars
          block={block}
          campaign={campaign}
          isAllowedToExecuteRequests={isAllowedToExecuteRequests}
          viewToken={viewToken}
        />
      ) : null}

      {block.type === 'Open Question' ? (
        <WordCloudsContainer
          questionId={block.id}
          results={block.results}
          surveyId={campaign.id}
          setAnswerSearch={setAnswerSearch}
          setView={setView}
          views={views}
          initialWordClouds={initialWordClouds}
          hiddenWordCloudWords={hiddenWordCloudWords}
          isAdmin={isAdmin}
          isAllowedToExecuteRequests={isAllowedToExecuteRequests}
          activeFiltersLength={activeFilters.length}
          wordCloudTabActive={view === views.WORD_CLOUD}
          openQuestionCategories={initialOpenQuestionCategories}
          assignCategoriesFromWords={(words, selectedCategories) =>
            assignCategoriesFromWords(words, selectedCategories)
          }
          openAnswerCategoryPairs={openAnswerCategoryPairs}
          viewToken={viewToken}
        />
      ) : null}
    </div>
  );
};

export default Question;
