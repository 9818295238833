const assignMissingPermissions = (permissions, permissionsToAssign) => {
  const permissionsToReturn = { ...permissions };

  Object.keys(permissionsToReturn).forEach(permission => {
    if (
      (permissionsToReturn[permission] === null ||
        permissionsToReturn[permission] === undefined) &&
      (permissionsToAssign[permission] ||
        permissionsToAssign[permission] === false)
    ) {
      permissionsToReturn[permission] = permissionsToAssign[permission];
    }
  });

  return permissionsToReturn;
};

export default (isAdmin, surveyInsightsPermissions, amPermissions) => {
  const defaultPermissionValues = {
    allowedToCreateInsights: true,
    allowedToViewInsights: true
  };

  if (isAdmin) {
    return defaultPermissionValues;
  }

  let permissions = {
    allowedToCreateInsights: null,
    allowedToViewInsights: null
  };

  if (surveyInsightsPermissions) {
    permissions = assignMissingPermissions(
      permissions,
      surveyInsightsPermissions
    );
  }

  if (amPermissions) {
    permissions = assignMissingPermissions(permissions, amPermissions);
  }

  // If there is no defined permissions, specify false
  permissions = assignMissingPermissions(permissions, {
    allowedToCreateInsights: false,
    allowedToViewInsights: false
  });

  return permissions;
};
