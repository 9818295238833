import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';

import useLazyQuery from '../../../../../../../../../surveys/hooks/useLazyQuery';

import {
  REQUEST_GEMINI_OPEN_ANSWERS_ANALYSIS,
  GET_GEMINI_OPEN_ANSWERS_ANALYSIS_DATA
} from '../../../../../../../../../graphql/ResultsAnalysis';

import prepareOpenAnswersAiAnalysis from '../../../helpers/prepareOpenAnswersAiAnalysis';

import ResultsAnalysisPopup from '../ResultsAnalysisPopup/ResultsAnalysisPopup';
import Loader from '../../../../../../../../../surveys/components/SurveyBuilderPage/Loader/Loader';
import AiAnswerAnalysisPopup from './components/AiAnswerAnalysisPopup';

import {
  questionResultsAnalysisPopupActions,
  aiAnalysisTypes
} from '../../../helpers/constants';

import aiIcon from '../../../../../../../../../assets/img/ai-icon.svg';

import styles from './AiAnswersAnalysis.module.css';

const AiAnswersAnalysis = props => {
  const { surveyId, questionId, results, resultsAnalysisData } = props;

  const [showResultsAnalysisPopup, setShowResultsAnalysisPopup] = useState(
    false
  );
  const [showAiPopup, setShowAiPopup] = useState(null);
  const [questionSummary, setQuestionSummary] = useState(
    resultsAnalysisData &&
      resultsAnalysisData.questionSummaries &&
      resultsAnalysisData.questionSummaries.length &&
      resultsAnalysisData.questionSummaries.some(
        qS => qS.questionId === questionId
      )
      ? resultsAnalysisData.questionSummaries.find(
          qS => qS.questionId === questionId
        )
      : null
  );

  const [timerValue, setTimerValue] = useState(0);
  const confirmTimer = useRef();

  const generateRichText = string => {
    if (!string) {
      return '';
    }

    const richText = {
      blocks: [
        {
          key: Math.random()
            .toString(36)
            .substr(2, 5),
          text: string,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {}
        }
      ],
      entityMap: {}
    };

    return JSON.stringify(richText);
  };

  const fetchAnalysisData = async analysisId => {
    const res = await getGeminiOpenAnswersAnalysisData({
      variables: {
        id: analysisId
      }
    });

    if (timerValue > 180) {
      if (confirmTimer && confirmTimer.current) {
        clearTimeout(confirmTimer.current);
      }

      setShowAiPopup({ error: true, dataRequestError: true });
    } else if (
      res &&
      res.data &&
      res.data.getGeminiOpenAnswersAnalysisData &&
      res.data.getGeminiOpenAnswersAnalysisData.errors &&
      res.data.getGeminiOpenAnswersAnalysisData.errors.length &&
      res.data.getGeminiOpenAnswersAnalysisData.errors[0].type ===
        'API_REQUEST_NOT_FINISHED'
    ) {
      // Polling every 3s
      confirmTimer.current = setTimeout(() => {
        fetchAnalysisData(analysisId);
      }, 3000);
      setTimerValue(prev => prev + 2);
    } else if (
      res &&
      res.data &&
      res.data.getGeminiOpenAnswersAnalysisData &&
      res.data.getGeminiOpenAnswersAnalysisData.success &&
      res.data.getGeminiOpenAnswersAnalysisData.categories &&
      res.data.getGeminiOpenAnswersAnalysisData.summary
    ) {
      if (confirmTimer && confirmTimer.current) {
        clearTimeout(confirmTimer.current);
      }

      setShowAiPopup(null);

      // Display popup with generated summary and categories
      setShowResultsAnalysisPopup({
        action: questionResultsAnalysisPopupActions.AI_ANALYSIS,
        ...(questionSummary && questionSummary._id
          ? { id: questionSummary._id }
          : {}),
        description: res.data.getGeminiOpenAnswersAnalysisData.summary
          ? generateRichText(res.data.getGeminiOpenAnswersAnalysisData.summary)
          : '',
        categories: res.data.getGeminiOpenAnswersAnalysisData.categories || [],
        analysisType: aiAnalysisTypes.CATEGORIZE_SUMMARIZE,
        analysisId
      });
    } else if (
      !res ||
      res.errors ||
      (res &&
        res.data &&
        res.data.getGeminiOpenAnswersAnalysisData &&
        res.data.getGeminiOpenAnswersAnalysisData.errors &&
        res.data.getGeminiOpenAnswersAnalysisData.errors.length &&
        res.data.getGeminiOpenAnswersAnalysisData.errors[0].type !==
          'API_REQUEST_NOT_FINISHED')
    ) {
      if (confirmTimer && confirmTimer.current) {
        clearTimeout(confirmTimer.current);
      }

      setShowAiPopup({ error: true, dataRequestError: true });
    }
  };

  const [
    requestGeminiOpenAnswersAnalysis,
    { loading: isAiAnalysisLoading }
  ] = useMutation(REQUEST_GEMINI_OPEN_ANSWERS_ANALYSIS, {
    onCompleted: r => {
      if (
        r &&
        r.requestGeminiOpenAnswersAnalysis &&
        r.requestGeminiOpenAnswersAnalysis.errors &&
        r.requestGeminiOpenAnswersAnalysis.errors.length
      ) {
        setShowAiPopup({ error: true, initialRequestError: true });
      } else if (
        r &&
        r.requestGeminiOpenAnswersAnalysis &&
        r.requestGeminiOpenAnswersAnalysis.id
      ) {
        setShowAiPopup({ analysisLoading: true });

        // Start fetching after 3s
        setTimeout(() => {
          fetchAnalysisData(r.requestGeminiOpenAnswersAnalysis.id);
        }, 3000);
      }
    },
    onError: () => setShowAiPopup({ error: true, initialRequestError: true })
  });

  const getGeminiOpenAnswersAnalysisData = useLazyQuery(
    GET_GEMINI_OPEN_ANSWERS_ANALYSIS_DATA,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const isLoading = isAiAnalysisLoading;

  const onClickSummarizeCategorize = () => {
    const preparedAnswers = prepareOpenAnswersAiAnalysis(results);

    requestGeminiOpenAnswersAnalysis({
      variables: {
        analysisType: aiAnalysisTypes.CATEGORIZE_SUMMARIZE,
        survey: surveyId,
        question: questionId,
        userResponses: preparedAnswers
      }
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <span
        className={`${styles.button} ${isLoading ? styles.buttonLoading : ''}`}
        role="presentation"
        onClick={() => {
          /* On implementation of sentiment analysis -> show ai popup here instead of executing 'onClickSummarizeCategorize' */

          // setShowAiPopup(true);
          onClickSummarizeCategorize();
        }}
      >
        <img src={aiIcon} alt="AI" className={styles.aiIcon} />
        Analyse with AI (BETA)
      </span>
      {showResultsAnalysisPopup ? (
        <ResultsAnalysisPopup
          showResultsAnalysisPopup={showResultsAnalysisPopup}
          setShowResultsAnalysisPopup={setShowResultsAnalysisPopup}
          surveyId={surveyId}
          questionId={questionId}
          setQuestionSummary={setQuestionSummary}
          results={results}
        />
      ) : null}
      {showAiPopup ? (
        <AiAnswerAnalysisPopup
          showAiPopup={showAiPopup}
          setShowAiPopup={setShowAiPopup}
          onClickSummarizeCategorize={onClickSummarizeCategorize}
          onClickSentimentAnalysis={() => {}}
        />
      ) : null}
    </>
  );
};

export default AiAnswersAnalysis;
