export default (populatedCategories, removedResults) => {
  const resultsWithCategories = populatedCategories.reduce(
    (results, category) => {
      const newResults = { ...results };

      category.results.forEach(r => {
        if (
          !(
            removedResults &&
            removedResults[category.id] &&
            removedResults[category.id].includes(r.resultId)
          )
        ) {
          if (newResults[r.resultId]) {
            newResults[r.resultId] = {
              ...newResults[r.resultId],
              categories: [
                ...(newResults[r.resultId].categories || []),
                category.categoryName
              ]
            };
          } else {
            newResults[r.resultId] = {
              resultId: r.resultId,
              blockId: r.block,
              categories: [category.categoryName]
            };
          }
        }
      });

      return newResults;
    },
    {}
  );

  return Object.values(resultsWithCategories);
};
