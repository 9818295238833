import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

import Loader from '../../../Loader/Loader';

import tickIcon from '../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../assets/img/delete.svg';

import styles from './InsightSettingsPopup.module.css';
import { UPDATE_INSIGHTS_PERMISSIONS } from '../../../../../graphql/InsightsData';

const InsightSettingsPopup = ({
  survey,
  surveyRefetch,
  setShowSettingsPopup
}) => {
  const [isCreateDisabled, setIsCreateDisabled] = useState(false);
  const [internalData, setInternalData] = useState({});

  const [updateInsightsPermissions, { loading }] = useMutation(
    UPDATE_INSIGHTS_PERMISSIONS
  );

  useEffect(
    () => {
      if (
        survey &&
        survey.insightsData &&
        survey.insightsData.permissions &&
        (survey.insightsData.permissions.allowedToViewInsights === true ||
          survey.insightsData.permissions.allowedToViewInsights === false)
      ) {
        setInternalData({ ...survey.insightsData.permissions });

        if (survey.insightsData.permissions.allowedToViewInsights === false) {
          setIsCreateDisabled(true);
        }
      } else if (
        survey &&
        survey.recordDetails &&
        survey.recordDetails.permissions &&
        survey.recordDetails.permissions !== ''
      ) {
        try {
          const amPermissions = JSON.parse(survey.recordDetails.permissions);

          if (
            amPermissions &&
            (amPermissions.allowedToViewInsights === true ||
              amPermissions.allowedToViewInsights === false)
          ) {
            setInternalData({
              allowedToCreateInsights: amPermissions.allowedToCreateInsights,
              allowedToViewInsights: amPermissions.allowedToViewInsights
            });

            if (amPermissions.allowedToViewInsights === false) {
              setIsCreateDisabled(true);
            }
          }
        } catch (error) {
          console.log(`Error parsing permissions string ${error}`);
        }
      } else {
        setInternalData({
          allowedToCreateInsights: false,
          allowedToViewInsights: false
        });
      }
    },
    [survey]
  );

  const onConfirmClick = async () => {
    const data = { ...internalData };
    data.survey = survey.id;

    await updateInsightsPermissions({
      variables: data
    });

    setInternalData({});
    surveyRefetch();
    setShowSettingsPopup(false);
  };

  const isLoading = loading;

  const onRadioClick = (permission, value) => {
    if (permission === 'allowedToCreateInsights' && isCreateDisabled) {
      return;
    }

    const newInternalData = { ...internalData };

    newInternalData[permission] = value;

    // Custom change
    if (permission === 'allowedToViewInsights' && value === false) {
      setIsCreateDisabled(true);
      newInternalData.allowedToCreateInsights = false;
    } else if (permission === 'allowedToViewInsights' && value === true) {
      setIsCreateDisabled(false);
    }

    setInternalData(newInternalData);
  };

  return (
    <div className={styles.backgroundContainer}>
      <div
        className={styles.popupContainer}
        role="presentation"
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.popupContent}>
          <div className={styles.title}>Edit permission for this survey</div>
          <div className={styles.fieldsContainer}>
            <div className={styles.subtitle}>Client can create insights</div>
            <div className={styles.licenceSelectorRow}>
              <div className={styles.licenceCheckboxContainer}>
                <input
                  type="radio"
                  name="create-insights"
                  onChange={() => onRadioClick('allowedToCreateInsights', true)}
                  checked={
                    internalData &&
                    internalData.allowedToCreateInsights === true
                  }
                  disabled={isCreateDisabled}
                />
                Yes
              </div>
              <div className={styles.licenceCheckboxContainer}>
                <input
                  type="radio"
                  name="create-insights"
                  onChange={() =>
                    onRadioClick('allowedToCreateInsights', false)
                  }
                  checked={
                    internalData &&
                    internalData.allowedToCreateInsights === false
                  }
                  disabled={isCreateDisabled}
                />
                No
              </div>
            </div>

            <div className={styles.subtitle}>Client can view insights</div>
            <div className={styles.licenceSelectorRow}>
              <div className={styles.licenceCheckboxContainer}>
                <input
                  type="radio"
                  name="view-insights"
                  onChange={() => onRadioClick('allowedToViewInsights', true)}
                  checked={
                    internalData && internalData.allowedToViewInsights === true
                  }
                />
                Yes
              </div>
              <div className={styles.licenceCheckboxContainer}>
                <input
                  type="radio"
                  name="view-insights"
                  onChange={() => onRadioClick('allowedToViewInsights', false)}
                  checked={
                    internalData && internalData.allowedToViewInsights === false
                  }
                />
                No
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.details} />
          <div className={styles.actions}>
            {!isLoading ? (
              <img
                className={styles.confirm}
                src={tickIcon}
                alt="Confirm icon"
                onClick={() => {
                  onConfirmClick();
                }}
                role="presentation"
              />
            ) : null}
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
            <img
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => {
                setInternalData({});
                setShowSettingsPopup(false);
              }}
              role="presentation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightSettingsPopup;
