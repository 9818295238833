import React from 'react';

import GeneratedCategory from './components/GeneratedCategory';

import styles from './GeneratedCategories.module.css';

const GeneratedCategories = props => {
  const { removedResults, setRemovedResults, populatedCategories } = props;

  const onResultRemoveToggle = (resultId, parentId, isRemoved) => {
    if (isRemoved) {
      setRemovedResults(prev => ({
        ...prev,
        [parentId]: [
          ...(prev && prev[parentId]
            ? [...prev[parentId]].filter(id => id !== resultId)
            : [])
        ]
      }));
    } else {
      setRemovedResults(prev => ({
        ...prev,
        [parentId]: [
          ...(prev && prev[parentId] ? prev[parentId] : []),
          resultId
        ]
      }));
    }
  };

  const onCategoryRemoveToggle = (parentId, isRemoved) => {
    const populatedCategory = populatedCategories.find(c => c.id === parentId);

    if (populatedCategory) {
      const categoryResultsIds = populatedCategory.results.map(r => r.resultId);
      if (isRemoved) {
        setRemovedResults(prev => ({
          ...prev,
          [parentId]: []
        }));
      } else {
        setRemovedResults(prev => ({
          ...prev,
          [parentId]: categoryResultsIds
        }));
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Generated categories</div>
      <div className={styles.categories}>
        {populatedCategories.map(category => (
          <GeneratedCategory
            key={category.id}
            category={category}
            onResultRemoveToggle={onResultRemoveToggle}
            onCategoryRemoveToggle={onCategoryRemoveToggle}
            isParentRemoved={
              removedResults &&
              removedResults[category.id] &&
              removedResults[category.id].length &&
              category.results.length === removedResults[category.id].length
            }
            removedResults={removedResults}
          />
        ))}
      </div>
    </div>
  );
};

export default GeneratedCategories;
