import React from 'react';

import ObjectID from 'bson-objectid';
import { useMutation } from '@apollo/react-hooks';

import Icon from '../../../../Icon';

import {
  CREATE_CONCEPT_TEST_GRAPH,
  CREATE_CUSTOM_GRAPH,
  CREATE_IDEA_SELECTION_GRAPH,
  CREATE_OPPORTUNITY_GRAPH,
  CREATE_VALUE_SELECTION_GRAPH
} from '../../../../../../graphql/Survey';

import styles from './MatrixQuestionCreateDecisionFrameworkButton.module.css';
import { templateTypes } from '../../Graphs/helpers/constants';
import getFeaturesFromQuestion from '../../../../../../surveys/components/SurveyBuilderPage/SurveyBuilder/state/actions/updateDecisionFramework/helpers/getFeaturesFromQuestion';
import frameWorkValueNames from '../../../../../../surveys/components/SurveyBuilderPage/SurveyBuilder/state/helpers/frameWorkValueNames';
import { DECISION_FRAMEWORK_TYPES } from '../../../../../../surveys/components/SurveyBuilderPage/SurveyBuilder/helpers/constants';

export default ({ block, surveyId, viewToken }) => {
  const [
    createOpportunityGraph,
    { loading: createOpportunityGraphLoading }
  ] = useMutation(CREATE_OPPORTUNITY_GRAPH);
  const [
    createCustomGraph,
    { loading: createCustomGraphLoading }
  ] = useMutation(CREATE_CUSTOM_GRAPH);
  const [
    createConceptTestGraph,
    { loading: createConceptTestGraphLoading }
  ] = useMutation(CREATE_CONCEPT_TEST_GRAPH);
  const [
    createValueSelectionGraph,
    { loading: createValueSelectionGraphLoading }
  ] = useMutation(CREATE_VALUE_SELECTION_GRAPH);
  const [
    createIdeaSelectionGraph,
    { loading: createIdeaSelectionGraphLoading }
  ] = useMutation(CREATE_IDEA_SELECTION_GRAPH);

  // eslint-disable-next-line
  const isLoading =
    createOpportunityGraphLoading ||
    createCustomGraphLoading ||
    createConceptTestGraphLoading ||
    createValueSelectionGraphLoading ||
    createIdeaSelectionGraphLoading;

  const graphqlRequests = {
    createOpportunityGraph,
    createCustomGraph,
    createConceptTestGraph,
    createValueSelectionGraph,
    createIdeaSelectionGraph
  };

  const onCreateDecisionFrameworkClick = async () => {
    if (block && block.matrix && block.matrix.decisionFrameworkType) {
      const valueNames = frameWorkValueNames.find(
        vNames => vNames.type === block.matrix.decisionFrameworkType
      );

      const features = getFeaturesFromQuestion(
        {
          ...block,
          choices: block.answers
        },
        valueNames.xQuestionName,
        valueNames.yQuestionName
      );

      const commonProperties = {
        id: ObjectID().toString(),
        name: 'Decision framework',
        features,
        xLabel: block.matrix.xLabel,
        yLabel: block.matrix.yLabel,
        question: block.id,
        survey: surveyId,
        ...(viewToken ? { viewToken } : {})
      };

      if (
        block.matrix.decisionFrameworkType === templateTypes.OPPORTUNITY_GRAPH
      ) {
        await graphqlRequests.createOpportunityGraph({
          variables: {
            ...commonProperties
          }
        });
      }

      if (block.matrix.decisionFrameworkType === templateTypes.CUSTOM_GRAPH) {
        await graphqlRequests.createCustomGraph({
          variables: {
            ...commonProperties
          }
        });
      }

      if (
        block.matrix.decisionFrameworkType ===
        templateTypes.VALUE_SELECTION_GRAPH
      ) {
        await graphqlRequests.createValueSelectionGraph({
          variables: {
            ...commonProperties
          }
        });
      }

      if (
        block.matrix.decisionFrameworkType ===
        templateTypes.IDEA_SELECTION_GRAPH
      ) {
        await graphqlRequests.createIdeaSelectionGraph({
          variables: {
            ...commonProperties
          }
        });
      }

      if (
        block.matrix.decisionFrameworkType === templateTypes.CONCEPT_TEST_GRAPH
      ) {
        await graphqlRequests.createConceptTestGraph({
          variables: {
            ...commonProperties
          }
        });
      }
    }
  };

  if (block.matrix.decisionFrameworkType === DECISION_FRAMEWORK_TYPES.SINGLE) {
    return null;
  }

  return (
    <div
      onClick={() => onCreateDecisionFrameworkClick(block)}
      className={styles.addInsightButton}
      role="presentation"
    >
      <div className={styles.icon}>
        <Icon type="merged-graph" />
      </div>
      Create decision framework
    </div>
  );
};
